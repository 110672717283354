import { Button, Col, Descriptions, Form, Input, Row, Select, Spin, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { UploadFile } from 'antd/lib';
import { LayoutNav, LayoutTabChatSetting, UploadImage } from 'components/layout.component';
import { RESPONSE_CODE_CHAT } from 'constants/response';
import { ENABLE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { enumToOptions } from 'utils/common';

// 聊天室設置 > 功能設定

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isEnable, setIsEnable] = useState(false);

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [settingInfo, setSettingInfo] = useState<any>();

  const { data: SettingInfo, isValidating, mutate } = $get({ url: 'api/chatsetting/setting' });
  useEffect(() => {
    if (SettingInfo) {
      setSettingInfo(SettingInfo);
    }
  }, [SettingInfo])
  useEffect(() => {
    if (SettingInfo) {
      form.setFieldsValue({
        state: SettingInfo.Data.WelcomeMessage ? ENABLE.enabled : ENABLE.disabled,
        welcomeContent: SettingInfo.Data.WelcomeMessage,
        defaultNickname: SettingInfo.Data.DefaultNickname,
      })
      SettingInfo.Data.DefaultAvatar && setImageData([
        {
          uid: SettingInfo.Data.DefaultAvatar,
          name: SettingInfo.Data.DefaultAvatar,
          url: SettingInfo.Data.DefaultAvatar,
        }
      ])
    }
  }, [SettingInfo]);

  const onCancel = () => {
    setIsEdit(false);
    form.setFieldsValue({
      state: SettingInfo.Data.WelcomeMessage ? ENABLE.enabled : ENABLE.disabled,
      welcomeContent: SettingInfo.Data.WelcomeMessage,
      defaultNickname: SettingInfo.Data.DefaultNickname,
    })
    setImageData([
      {
        uid: SettingInfo.Data.DefaultAvatar,
        name: SettingInfo.Data.DefaultAvatar,
        url: SettingInfo.Data.DefaultAvatar,
      }
    ])
    mutate();
  }

  const onFinish = (value: any) => {
    $post({
      url: 'api/chatsetting/setting/update',
      send: {
        Id: SettingInfo.Data.Id,
        WelcomeMessage: value.welcomeContent,
        DefaultNickname: value.defaultNickname,
        DefaultAvatar: (imageData && imageData[0]) ? imageData[0].url : SettingInfo.Data.DefaultAvatar
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        mutate();
        setIsEdit(false);
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <LayoutTabChatSetting activeKey='3' />
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="end" gutter={[12, 12]}>
                {
                  !isEdit && $p('70402') &&
                  <Col><Button type="primary" onClick={() => setIsEdit(true)}>{i18n.t('edit')}</Button></Col>
                }
                {
                  isEdit && <>
                    <Col><Button onClick={onCancel}>{i18n.t('cancel')}</Button></Col>
                    <Col><Button type="primary" loading={false} htmlType="submit">{i18n.t('confirm')}</Button></Col>
                  </>
                }
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Descriptions bordered size="small" labelStyle={{ width: 200 }} contentStyle={{ width: 500 }}>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('welcomeMessageStatus')}</>}>
                    <Form.Item name="state" rules={[{ required: true }]}>
                      <Select
                        className="w-full"
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(ENABLE)}
                        onChange={setIsEnable}
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('welcomeMessageContent')}</>}>
                    <Form.Item name="welcomeContent"
                      rules={[{ required: true, type: 'string', min: 4, max: 100, message: `${i18n.t('inputBetween4And100Characters')}` }]}>
                      <Input
                        placeholder={`${i18n.t('inputData')}`}
                        disabled={!isEdit && !isEnable}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('defaultAvatar')}>
                    <Form.Item name="DefaultAvatar" valuePropName="fileList">
                      <UploadImage
                        name="DefaultAvatar"
                        url={'/upload/images/public/message'}
                        preUrl={settingInfo ? settingInfo.DefaultAvatar : ''}
                        imageData={imageData}
                        setImageData={setImageData}
                        w={100}
                        h={100}
                        // cropShape="round"
                        disabled={!isEdit && !isEnable}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* <Descriptions.Item label={""}>
                  </Descriptions.Item>
                  <Descriptions.Item label={""}>
                  </Descriptions.Item> */}
                </Descriptions>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;