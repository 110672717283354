import { Button, Col, Form, Input, Modal, Row, Select, Spin, Switch, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import { RESPONSE_CODE, RESPONSE_CODE_FRONT } from 'constants/response';
import dayjs from 'dayjs';
import { ENABLE, FRONT_COPY_TYPE, SLIDE_TYPE, WHETHER } from 'enum/state';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { enumToOptions } from 'utils/common';
import { UploadImage } from './layout.component';
import useSite from 'hooks/site.hook';
import { COOKIE } from 'constants/cookie';
import { FormInputPosition } from './form.component';

export const PopupAddFront = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();

  const onFinish = (formData: any) => {
    const Lang = Cookies.get('lang');
    $post({
      url: 'api/contents/frontpage/create',
      send: {
        Lang,
        Type: formData.type,
        Title: formData.title,
        Position: formData.Position
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  };

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{
        Position: 0
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('selectCategory')}`}
                options={enumToOptions(FRONT_COPY_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('mainTitle')} rules={[
              { required: true },
              { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
            ]}>
              <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                placeholder={`${i18n.t('inputData')}${i18n.t('mainTitle')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormInputPosition />
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

export const PopupEditFront = ({ isOpen, close, data, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        type: data.Type,
        title: data.title,
        Position: data.sort
      })
    }
  }, [data, isOpen]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/contents/frontpage/update',
      send: {
        Id: data.key,
        Title: formData.title,
        Position: formData.Position
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  };

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                disabled
                className="w-full"
                placeholder={`${i18n.t('selectCategory')}`}
                options={enumToOptions(FRONT_COPY_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('mainTitle')} rules={[
              { required: true },
              { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
            ]}>
              <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                placeholder={`${i18n.t('inputData')}${i18n.t('mainTitle')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormInputPosition />
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

export const PopupAddFrontSub = ({ isOpen, close, type, main, mutate, FrontList }: any) => {
  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [isTextOrPhoto, setIsTextOrPhoto] = useState(false);

  useEffect(() => {
    if (main && type) {
      form.setFieldsValue({
        type,
        mainTitle: main
      })
    }
  }, [main, type, FrontList]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/contents/frontpage/sub/create',
      send: {
        ContentsId: main,
        Title: formData.subTitle,
        Content: !isTextOrPhoto ? formData.content : "",
        Photo: isTextOrPhoto ? imageData[0].url : "",
        Position: formData.Position
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  };

  const onClose = () => {
    form.setFieldsValue({
      subTitle: null,
      content: null,
      Position: 0,
      fileList: null,
      Photo: null,
    })
    setImageData([]);
    setIsTextOrPhoto(false);
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
        Position: 0
      }} >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                disabled
                className="w-full"
                placeholder={`${i18n.t('selectCategory')}`}
                options={enumToOptions(FRONT_COPY_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="mainTitle" label={i18n.t('mainTitle')} rules={[{ required: true }]}>
              <Select
                disabled
                className="w-full"
                placeholder={`${i18n.t('pleaseChooseSubtitle')}`}
                options={
                  (FrontList && main && type) ?
                    FrontList.Data.map((item: FrontList) => (
                      { value: item.Id, label: item.Title }
                    )) : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="subTitle" label={i18n.t('subTitle')} rules={[
              { required: true },
              { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
            ]}>
              <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                placeholder={`${i18n.t('inputData')}${i18n.t('subTitle')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            {
              isTextOrPhoto
                ? <>
                  <Form.Item name="Photo" valuePropName="fileList" label={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col>{i18n.t('internalContent')}</Col>
                      <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                      <Col>{i18n.t('image')}</Col>
                    </Row>
                  } rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
                    <UploadImage
                      form={form}
                      name="Photo"
                      url={'/upload/images/public/contents'}
                      imageData={imageData}
                      setImageData={setImageData}
                      w={414}
                    />
                  </Form.Item>
                </>
                : <Form.Item name="content" label={
                  <Row align="middle" gutter={[12, 12]}>
                    <Col>{i18n.t('internalContent')}</Col>
                    <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                    <Col>{i18n.t('image')}</Col>
                  </Row>
                } rules={[
                  { required: true, message: `${i18n.t('required')}` },
                  { max: 1000, message: `${i18n.t('exceedsCharacterLimit1000')}` }
                ]}>
                  <TextArea autoSize={{ minRows: 5, maxRows: 5 }} showCount maxLength={1000}
                    placeholder={`${i18n.t('inputData')}${i18n.t('internalContent')}`} />
                </Form.Item>
            }
          </Col>
          <Col span={24}>
            <FormInputPosition />
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  {i18n.t('confirm')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

export const PopupEditFrontSub = ({ isOpen, close, id, type, main, mutate, FrontList }: any) => {
  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [isTextOrPhoto, setIsTextOrPhoto] = useState(false);

  const { data: FrontSubInfo, mutate: mutateOne, isValidating: loadingInfo } = $get({
    url: 'api/contents/frontpage/sub/one',
    params: { Id: id },
    allow: !!id,
  })

  useEffect(() => {
    if (isOpen) mutateOne()
  }, [isOpen]);
  useEffect(() => {
    if (FrontSubInfo && isOpen) {
      form.setFieldsValue({
        type,
        mainTitle: main,
        subTitle: FrontSubInfo?.Data[0].Title,
        content: FrontSubInfo?.Data[0].Content,
        Position: FrontSubInfo?.Data[0].Position,
      })
      FrontSubInfo?.Data[0]?.Photo && setImageData([
        {
          uid: FrontSubInfo?.Data[0].Photo,
          name: FrontSubInfo?.Data[0].Photo,
          url: FrontSubInfo?.Data[0].Photo,
        },
      ]);
      setIsTextOrPhoto(FrontSubInfo?.Data[0].Photo);
    }
  }, [main, type, FrontSubInfo, isOpen]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/contents/frontpage/sub/update',
      send: {
        Id: id,
        Title: formData.subTitle,
        Content: !isTextOrPhoto ? formData.content : "",
        Photo: isTextOrPhoto ? imageData[0].url : "",
        Position: formData.Position
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  };

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Spin spinning={loadingInfo}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
                <Select
                  disabled
                  className="w-full"
                  placeholder={`${i18n.t('selectCategory')}`}
                  options={enumToOptions(FRONT_COPY_TYPE)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="mainTitle" label={i18n.t('mainTitle')} rules={[{ required: true }]}>
                <Select
                  disabled
                  className="w-full"
                  placeholder={`${i18n.t('pleaseChooseSubtitle')}`}
                  options={
                    (FrontList && main && type) ?
                      FrontList.Data.map((item: FrontList) => (
                        { value: item.Id, label: item.Title }
                      )) : []
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="subTitle" label={i18n.t('subTitle')} rules={[
                { required: true },
                { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
              ]}>
                <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                  placeholder={`${i18n.t('inputData')}${i18n.t('subTitle')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              {
                isTextOrPhoto
                  ? <>
                    <Form.Item name="Photo" valuePropName="fileList" label={
                      <Row align="middle" gutter={[12, 12]}>
                        <Col>{i18n.t('internalContent')}</Col>
                        <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                        <Col>{i18n.t('image')}</Col>
                      </Row>
                    } rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
                      {FrontSubInfo !== undefined && FrontSubInfo?.Data[0]?.Photo !== "" ?
                        <UploadImage
                          name="Photo"
                          url={'/upload/images/public/contents'}
                          preUrl={FrontSubInfo ? FrontSubInfo.Data[0]?.Photo : ''}
                          imageData={imageData}
                          setImageData={setImageData}
                          w={414}
                        /> :
                        <UploadImage
                          form={form}
                          name="Photo"
                          url={'/upload/images/public/contents'}
                          imageData={imageData}
                          setImageData={setImageData}
                          w={414}
                        />}
                    </Form.Item>
                  </>
                  : <Form.Item name="content" label={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col>{i18n.t('internalContent')}</Col>
                      <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                      <Col>{i18n.t('image')}</Col>
                    </Row>
                  } rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    { max: 1000, message: `${i18n.t('exceedsCharacterLimit1000')}` }
                  ]}>
                    <TextArea autoSize={{ minRows: 5, maxRows: 5 }} showCount maxLength={1000}
                      placeholder={`${i18n.t('inputData')}${i18n.t('internalContent')}`} />
                  </Form.Item>
              }
            </Col>
            <Col span={24}>
              <FormInputPosition />
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[12, 12]}>
                <Col>
                  <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {i18n.t('confirm')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal >
  )
}

// 輪播圖新增編輯
export const PopupFrontCarousel = ({ isOpen, close, data, mutate, count }: any) => {
  const [form] = useForm();
  const [isLinkDisabled, setIsLinkDisabled] = useState(true);
  const [uploadType, setUploadType] = useState(SLIDE_TYPE.pcVersion);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const { data: $s } = useSite();

  useEffect(() => {
    if (count) {
      if (count.WebTotalRecord >= 10) {
        setUploadType(SLIDE_TYPE.mobileVersion);
      }
      if (count.MobileTotalRecord >= 10) {
        setUploadType(SLIDE_TYPE.pcVersion);
      }
    }
  }, [count]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        Category: data.Category,
        LinkTarget: data.LinkTarget,
        LinkURL: data.LinkURL,
        Status: data.Status,
        Position: data.Position,
      })
      setImageData([{
        uid: data.Photo_1,
        name: data.Photo_1,
        url: data.Photo_1,
      }])
      setIsLinkDisabled(!data.LinkTarget);
      setUploadType(data.Category);
    }
  }, [data, isOpen]);

  const onChangeCategory = (select: number) => {
    setUploadType(select);
    setIsLinkDisabled(true);
    setImageData([]);
  }

  const onChangeLinkTarget = (type: number) => {
    setIsLinkDisabled(!(type === 1));
    form.setFieldValue('LinkURL', '');
  }

  const onFinish = (formData: any) => {
    const Lang = Cookies.get(COOKIE.LANG);
    const send: any = {
      Photo_1: imageData[0].url,
      LinkTarget: formData.LinkTarget,
      LinkURL: formData.LinkURL,
      Status: formData.Status,
      Position: formData.Position,
    }
    if (!data) {
      send.Lang = Lang;
      send.PublishDate = dayjs().tz($s && $s.TimeZone).format('YYYY-MM-DD HH:mm:ss');
      switch (formData.Category) {
        case SLIDE_TYPE.mobileVersion:
          $post({
            url: 'api/contents/mobilemainslide/create',
            send,
            success: () => {
              message.success(i18n.t('addSuccess'))
              mutate();
              onClose();
            },
            resCode: RESPONSE_CODE
          })
          break;
        case SLIDE_TYPE.pcVersion:
          $post({
            url: 'api/contents/webmainslide/create',
            send,
            success: () => {
              message.success(i18n.t('addSuccess'))
              mutate();
              onClose();
            },
            resCode: RESPONSE_CODE
          })
          break;
      }
    } else {
      send.Id = data.key;
      $post({
        url: 'api/contents/mainslide/update',
        send,
        success: () => {
          message.success(i18n.t('editSuccess'))
          mutate();
          onClose();
        },
        resCode: RESPONSE_CODE
      })
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={!data ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
        Category: uploadType,
        Position: 0,
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Category" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                disabled={!!data}
                className="w-full"
                onChange={onChangeCategory}
                options={[
                  { value: SLIDE_TYPE.pcVersion, label: i18n.t('pcVersion'), disabled: count.WebTotalRecord >= 10 },
                  { value: SLIDE_TYPE.mobileVersion, label: i18n.t('mobileVersion'), disabled: count.MobileTotalRecord >= 10 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('image')} 
              rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]} required>
              <UploadImage
                name="Photo_1"
                url={'/upload/images/public/contents'}
                preUrl={data ? data.Photo_1 : ''}
                imageData={imageData}
                setImageData={setImageData}
                w={uploadType === SLIDE_TYPE.mobileVersion ? 540 : 1920}
                h={uploadType === SLIDE_TYPE.mobileVersion ? 210 : 488}
                form={form}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="LinkTarget" label={i18n.t('openLinkInNewWindow')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                onChange={onChangeLinkTarget}
                options={enumToOptions(WHETHER)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="LinkURL" label={i18n.t('linkURL')} rules={[{ required: !isLinkDisabled }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('linkURL')}`} disabled={isLinkDisabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormInputPosition />
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}