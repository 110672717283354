import { Button, Col, Form, Input, Row, Select, Spin, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, Export, LayoutNav, LayoutPagination, LayoutUpdateControl } from 'components/layout.component';
import { PopupAgentDeposit } from 'components/withdraw.component';
import { ExcelColumns } from 'constants/excel';
import { GATEWAY_CODE_DEPOSIT } from 'constants/gateway';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { DEPOSIT_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeL2S, timeS2L } from "utils/common";

// 代理入款查詢

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = Form.useForm();
  const [reset, setReset] = useState(false);
  const [id, setId] = useState(0);
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [endDate, setEndDate] = useState('CreateEndDate');
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<any>({
    CreateStartDate: timeL2S(date[0]),
    CreateEndDate: timeL2S(date[1]),
  })
  const { data: depositList, isValidating, mutate } = $get({
    url: 'api/depositticket/agent/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const { data: gatewayList } = $get({
    url: 'api/gateway/all/list',
    params: { Type: 0 }
  })

  const defaultDate = SEARCH_DATE_TYPE.createTime;

  const handleTimeMode = (type: number) => {
    if (type === defaultDate) {
      setStartDate('CreateStartDate');
      setEndDate('CreateEndDate');
    } else {
      setStartDate('AuditedStartDate');
      setEndDate('AuditedEndDate');
    }
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Code: formData.code,
        Account: formData.agAccount,
        SAAccount: formData.saAccount,
        Status: formData.status,
        GatewayId: formData.depositMerchantName,
        [startDate]: timeL2S(date[0]),
        [endDate]: timeL2S(date[1]),
      })
    }
    mutate();
  };

  const onClear = () => {
    setReset(!reset);
    form.resetFields();
    setPage([1, 10]);
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
  }

  const stateColor = (type: number) => {
    switch (type) {
      case DEPOSIT_STATE.success:
      case DEPOSIT_STATE.approvaled:
        return 'color-pass'
      case DEPOSIT_STATE.processing:
        return 'color-padding'
      case DEPOSIT_STATE.paying:
        return 'color-up'
      case DEPOSIT_STATE.pendingFinancialReview:
        return 'color-reviewing'
      case DEPOSIT_STATE.fail:
      case DEPOSIT_STATE.reject:
        return 'color-reject'
    }
  }

  const handleDeposit = (id: number) => {
    setId(id);
    setIsOpenDeposit(true);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: defaultDate,
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="code" rules={[{
                type: 'string',
                min: 25,
                message: `${i18n.t('orderNumberMustBe25Characters')}`
              }]}>
                <Input maxLength={25} placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="agAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="saAccount">
                <Input placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="depositMerchantName">
                <Select
                  className="w-full"
                  placeholder={i18n.t('depositMethod')}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...((gatewayList && gatewayList.Data) || []).map((item: any) => (
                      { value: item.Id, label: i18n.t(item.Code) }
                    ))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...common.enumToOptions(DEPOSIT_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" justify="space-between">
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      className="w-full"
                      onChange={handleTimeMode}
                      options={common.enumToOptions(SEARCH_DATE_TYPE)}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
                <Col>
                  <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <LayoutUpdateControl callback={mutate} />
            </Col>
          </Row>
        </Form>
        <Spin spinning={isValidating}>
          <Table
            className="mt-1"
            size="middle"
            dataSource={
              depositList ? [
                {
                  key: 1,
                  count: depositList.TotalRecord,
                  total: depositList.TotalRealAmount
                }
              ] : []
            }
            columns={[
              {
                title: i18n.t('count'),
                dataIndex: 'count',
                align: 'right',
                width: '50%'
              },
              {
                title: i18n.t('total'),
                dataIndex: 'total',
                align: 'right',
                width: '50%',
                render: (_, { total }) => common.toFormatNumber(total)
              },
            ]}
            pagination={false}
          />
        </Spin>
        <Row gutter={[12, 12]} className="pb-1 mt-1">
          {
            $p('40602') &&
            <Col>
              <Export url={'/depositticket/agent/list/export'} param={{
                ...params, PageSize: depositList && depositList.TotalRecord, PageIndex: 1
              }} sheetName={i18n.t('agentDepositQuery')} columns={ExcelColumns.AgentDeposit} />
            </Col>
          }
        </Row>
        <Table
          size="small"
          scroll={{ x: 1500 }}
          loading={isValidating}
          dataSource={depositList?.Data?.map((item: AgentDeposit) => ({ key: item.Id, ...item }))}
          columns={[
            {
              dataIndex: 'Code',
              title: i18n.t('order'),
              fixed: "left",
              width: 300
            },
            {
              dataIndex: 'AgentAccount',
              title: i18n.t('account'),
              width: 200
            },
            {
              dataIndex: 'SAAccount',
              title: i18n.t('generalAgent'),
              width: 200,
            },
            {
              dataIndex: 'GatewayCode',
              title: i18n.t('depositMethod'),
              width: 150,
              render: (val) => i18n.t(val)
            },
            {
              dataIndex: 'BeforeAmount',
              title: i18n.t('previousBalance'),
              align: 'right',
              width: 150,
              render: (val) => val ? '-' : common.toFormatNumber(val)
            },
            {
              dataIndex: 'Amount',
              title: i18n.t('depositAmountMoney'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
            {
              dataIndex: 'HandlingFee',
              title: i18n.t('paymentProcessingFee'),
              align: 'right',
              width: 150,
              render: (val, { Status }: any) =>
                [DEPOSIT_STATE.approvaled, DEPOSIT_STATE.success].includes(Status)
                  ? common.toFormatNumber(val)
                  : '-'
            },
            {
              dataIndex: 'ExchangeRate',
              title: i18n.t('exchangeRate'),
              align: 'right',
              width: 100,
              render: (val, { GatewayCode }) =>
                GatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬 ? common.toFormatNumber(val || 0, 2 ) : '-'
            },
            {
              dataIndex: 'AfterAmount',
              title: i18n.t('BalanceAfterModification'),
              align: 'right',
              width: 150,
              render: (val) => val ? '-' : common.toFormatNumber(val)
            },
            {
              dataIndex: 'CreateDate',
              title: i18n.t('createTime'),
              width: 150,
              render: (val: string) => timeS2L(val)
            },
            {
              dataIndex: 'AuditedDate',
              title: i18n.t('auditTime'),
              width: 150,
              render: (val, { CompletedDate, Status }) => (
                Status === DEPOSIT_STATE.approvaled
                  ? timeS2L(val)
                  : Status === DEPOSIT_STATE.success
                    ? timeS2L(CompletedDate)
                    : '-'
              )
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              width: 150,
              fixed: 'right',
              render: (val, record: any) => (
                <>
                  <div className={stateColor(val)}>{i18n.t(DEPOSIT_STATE[val])}</div>
                  {
                    val !== DEPOSIT_STATE.pendingFinancialReview &&
                    <div>{i18n.t('from')} {record.FinanceAccount || 'System'} {i18n.t('operation')}</div>
                  }
                </>
              )
            },
            {
              title: i18n.t('operation'),
              width: 100,
              fixed: 'right',
              render: (_, { Status, key }) => (
                <>
                  {
                    (Status === DEPOSIT_STATE.pendingFinancialReview && $p('40603')) &&
                    <Button className="size-12" type="link" onClick={() => handleDeposit(key)}>{i18n.t('deposit')}</Button>
                  }
                  {
                    (Status !== DEPOSIT_STATE.pendingFinancialReview || !$p('40603')) &&
                    <Button className="size-12" type="link" onClick={() => handleDeposit(key)}>{i18n.t('detail')}</Button>
                  }
                </>
              ),
            },
          ]}
          pagination={false}
        />
        <LayoutPagination total={depositList ? depositList.TotalRecord : 0} setPage={setPage} page={page} />
      </Content>
      <PopupAgentDeposit isOpen={isOpenDeposit} close={() => setIsOpenDeposit(false)} id={id} mutate={mutate} />
    </div >
  );
};

export default PageMain;