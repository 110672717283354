import { Button, Col, Form, Input, Row, Spin, Table, message } from "antd"
import { useForm } from "antd/es/form/Form"
import { Content } from "antd/lib/layout/layout"
import { LayoutNav } from "components/layout.component"
import i18n from "i18n"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { $get, $post } from "services"
import { toFormatNumber as $f, verify } from 'utils/common'

// 交收報表 設定

const PageMain = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<any>([]);

  const { data: settingList, isValidating } = $get({ url: 'api/providerreceivable/setting/list' });

  useEffect(() => {
    if (settingList && settingList.Data && !isEdit) {
      const ary: any = [];
      const apiList = JSON.parse(JSON.stringify(settingList.Data))
      apiList.forEach((record: any) => {
        record.Detail.forEach((item: any, i: number) => {
          item.rowSpan = i === 0 ? record.Detail.length : 0;
          ary.push(item);
          form.setFieldValue(`rate-${item.Id}`, $f(item.Rate * 100, 2));
        });
      });
      setList(ary);
    }
  }, [settingList, isEdit])

  const onFinish = (formData: any) => {
    const send = list.map((item: any) => ({
      ProviderId: item.ProviderId,
      ProviderCode: item.ProviderCode,
      CategoryId: item.CategoryId,
      CategoryCode: item.CategoryCode,
      Rate: parseFloat(formData[`rate-${item.Id}`]) / 100,
    }))
    $post({
      url: 'api/providerreceivable/setting/update',
      send,
      success: () => {
        message.success(i18n.t('updateSuccess'));
        navigate('/report/settlement');
      }
    }, setIsLoading)
  }

  const onCancel = () => {
    setIsEdit(false);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="end" gutter={[12, 12]}>
                {isEdit
                ?
                <>
                <Col><Button onClick={onCancel}>{i18n.t('cancel')}</Button></Col>
                <Col><Button type="primary" htmlType="submit" loading={isLoading}>{i18n.t('confirm')}</Button></Col>
                </>
                :
                <Col><Button type="primary" onClick={() => setIsEdit(true)}>{i18n.t('edit')}</Button></Col>
                }
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  bordered
                  dataSource={list.map((item: any) => ({ key: item.Id, ...item }))}
                  columns={[
                    {
                      dataIndex: 'ProviderCode',
                      title: i18n.t('gameProvider'),
                      width: '30%',
                      onCell: (record: any) => ({ rowSpan: record.rowSpan })
                    },
                    {
                      dataIndex: 'CategoryCode',
                      title: i18n.t('gameCategory'),
                      width: '35%',
                      render: (val) => i18n.t(val)
                    },
                    {
                      dataIndex: 'Rate',
                      title: `${i18n.t('rate')}%`,
                      width: '35%',
                      render: (val, record, i) => <>
                        <Form.Item name={`rate-${record.Id}`} rules={verify({ max: 100 })}>
                          <Input placeholder={'0.00'} disabled={!isEdit} />
                        </Form.Item>
                      </>
                    },
                  ]}
                  pagination={false}
                />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  )
}

export default PageMain