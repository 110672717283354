import { Button, Col, Form, Image, Input, Modal, Row, Select, Space, Switch, Table, UploadFile, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import hintIcon from 'assets/image/icon-12.png';
import systemDefaultGameIcon from 'assets/image/system defalt_Game icon.png';
import { RESPONSE_CODE, RESPONSE_CODE_GAME, RESPONSE_CODE_LICENSE, RESPONSE_CODE_MAINTENANCE, RESPONSE_CODE_PERMISSION } from 'constants/response';
import dayjs from 'dayjs';
import { ENABLE, PERMISSION_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, gameNameTransfer, selectFuzzyFilter, specialProviderName, timeL2S, timeS2L, verify } from "utils/common";
import { DatePickerCol, UploadImage } from './layout.component';
import { COOKIE } from "../constants/cookie";
import { FormInputPosition } from './form.component';

// 權限
export const ViewPermissionList = ({ data, setParams, params, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();

  const update = (item: any) => {
    $post({
      url: 'api/permission/update',
      send: {
        Id: item.Id,
        Name: item.Name,
        Remark: item.Remark,
        Status: item.Status === 1 ? 0 : 1,
        PermissionCode: JSON.parse(item.PermissionCode),
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        mutate();
      },
      fail: () => {
        if (JSON.parse(item.PermissionCode).length === 0) {
          message.error(i18n.t('permissionsNotChecked'));
        }
      },
      resCode: RESPONSE_CODE
    })
  };

  return (
    <Table
      size="middle"
      scroll={{ x: 1500 }}
      dataSource={data?.Data.map((item: any) => ({ ...item, key: item.Id }))}
      columns={[
        {
          dataIndex: 'Name',
          title: i18n.t('permissionName'),
          width: 300,
          fixed: 'left'
        },
        {
          dataIndex: 'Remark',
          title: i18n.t('description'),
          width: 350,
          render: (val) => val || '-'
        },
        {
          dataIndex: 'Status',
          title: i18n.t('status'),
          align: 'center',
          width: 100,
          render: (val, record: any) => <Switch checked={val === PERMISSION_STATE.enabled} onChange={v => update(record)} disabled={!$p('00704')} />
        },
        {
          dataIndex: 'CreateDate',
          title: i18n.t('createTime'),
          width: 200,
          render: (val: string) => timeS2L(val)
        },
        {
          dataIndex: 'LastModifyAccount',
          title: i18n.t('updater'),
          width: 200,
          render: (val) => val || 'System'
        },
        {
          dataIndex: 'UpdateDate',
          title: i18n.t('updateTime'),
          width: 200,
          render: (val: string) => timeS2L(val)
        },
        {
          title: i18n.t('operation'),
          width: 150,
          fixed: 'right',
          render: (_, { Id }: any) => (
            <>
              {$p('00704') && <Button className="size-12" type="link"
                onClick={() => navigate(`/advanced/permission_name_edit/${Id}`)}
              >{i18n.t('edit')}</Button>}
              {$p('00801') && <Button className="size-12" type="link"
                onClick={() => navigate(`/advanced/permission_backOfficeAccount/${Id}`)}
              >{i18n.t('userAccount')}</Button>}
            </>
          )
        },
      ]}
      pagination={{
        showSizeChanger: true,
        showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
        total: data?.TotalRecord,
        pageSize: params.PageSize
      }}
      onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
    />
  )
}
export const PopupAddPermission = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();

  const onFinish = (formData: any) => {
    $post({
      url: 'api/permission/create',
      send: {
        Name: formData.Name,
        Remark: formData.Remark,
        Status: formData.Status
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE
    })
  };

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      forceRender
      footer={
        <Row justify="center" gutter={[12, 12]} className="mt-1">
          <Col><Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button></Col>
          <Col><Button key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="Name" label={i18n.t('permissionName')} rules={[{ required: true }]}>
          <Input placeholder={`${i18n.t('inputData')}`} maxLength={100} showCount />
        </Form.Item>
        <Form.Item name="Remark" label={i18n.t('description')}
          rules={[{ type: 'string', max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }]}>
          <Input placeholder={`${i18n.t('inputData')}`} />
        </Form.Item>
        <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
          <Select
            placeholder={`${i18n.t('pleaseSelect')}`}
            options={enumToOptions(PERMISSION_STATE)}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

// 後台帳號
export const ViewPermissionBackOffice = ({ accountList, mutate, setParams, params }: any) => {
  const { permissions: $p } = useAccount();
  const [singleAccount, setSingleAccount] = useState({});
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);

  const handleEdit = (data: any) => {
    setIsOpenEdit(true);
    setSingleAccount(data);
  }

  const handlePassword = (data: any) => {
    setIsOpenPassword(true);
    setSingleAccount(data);
  }

  const handleEditState = (record: any) => {
    $post({
      url: 'api/account/update',
      send: {
        Id: record.Id,
        Name: record.Name,
        Status: record.Status === 1 ? 0 : 1,
        Remark: record.Remark
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      }
    })
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={accountList?.Data.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'PermissionName',
            title: i18n.t('permissionName'),
            fixed: 'left',
            width: 180
          },
          {
            dataIndex: 'Account',
            title: i18n.t('backOfficeAccount'),
            fixed: 'left',
            width: 180
          },
          {
            dataIndex: 'Name',
            title: i18n.t('nameTitle'),
            width: 180,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Remark',
            title: i18n.t('description'),
            width: 500,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            align: 'center',
            width: 100,
            render: (val, record: any) =>
              <Switch checked={val === PERMISSION_STATE.enabled} onChange={v => handleEditState(record)} disabled={!$p('00803')} />
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
            render: (val: string) => timeS2L(val)
          },
          {
            dataIndex: 'LastModifyAccount',
            title: i18n.t('updater'),
            width: 200,
            render: (val) => val || 'System'
          },
          {
            dataIndex: 'UpdateDate',
            title: i18n.t('updateTime'),
            width: 150,
            render: (val: string) => timeS2L(val)
          },
          {
            title: i18n.t('operation'),
            width: 150,
            fixed: 'right',
            render: (_, record: any) => (
              <>
                {$p('00803') && <Button className="size-12" type="link"
                  onClick={() => handleEdit(record)}>{i18n.t('edit')}</Button>}
                {$p('00804') && <Button className="size-12" type="link"
                  onClick={() => handlePassword(record)}>{i18n.t('changePassword')}</Button>}
              </>
            )
          },
        ]}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
          total: accountList?.TotalRecord,
          pageSize: params.PageSize
        }}
        onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
      />
      <PopupEditBackOfficeAccount isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} singleAccount={singleAccount} mutate={mutate} />
      <PopupBackOfficeAccountPassword isOpen={isOpenPassword} close={() => setIsOpenPassword(false)} singleAccount={singleAccount} mutate={mutate} />
    </>
  )
}
export const PopupAddBackOfficeAccount = ({ isOpen, close, mutate, permissionList }: any) => {
  const [form] = useForm();

  const onFinish = (formData: any) => {
    if (formData.Password !== formData.TransactionPassword) {
      return message.error(i18n.t('INCORRECT_REPEAT_PASSWORD'))
    }
    $post({
      url: 'api/account/create',
      send: {
        Account: formData.Account,
        Name: formData.Name,
        Password: formData.Password,
        TransactionPassword: formData.TransactionPassword,
        Remark: formData.Remark,
        PermissionId: `${formData.PermissionId}`
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_PERMISSION
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      width={450}
      onCancel={onClose}
      centered
      forceRender
      footer={
        <Row justify="center" className='mt-1'>
          <Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('backOfficeAccount')}
              rules={[...verify({ point: 5 }), { type: 'string', min: 4, max: 12 }]} >
              <Input placeholder={`4~12${i18n.t('requiresEnglishAndNumbers')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('nameTitle')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} maxLength={10} showCount />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Password" label={i18n.t('password')}
              rules={[...verify({ point: 6 }), { type: 'string', min: 4, max: 20 }]}>
              <Input.Password placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="TransactionPassword" label={i18n.t('confirmNewPassword')}
              rules={[...verify({ point: 6 }), { type: 'string', min: 4, max: 20 }]}>
              <Input.Password placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Remark" label={i18n.t('description')}
              rules={[{ type: 'string', max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="PermissionId" label={i18n.t('selectPermissions')} rules={[{ required: true }]}>
              <Select
                className="w-12"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={permissionList?.Data.map((item: any) => ({
                  value: item.Id, label: item.Name
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupEditBackOfficeAccount = ({ isOpen, close, singleAccount, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (singleAccount && isOpen) {
      form.setFieldsValue({
        Account: singleAccount.Account,
        Status: singleAccount.Status,
        Remark: singleAccount.Remark,
        Name: singleAccount.Name,
      })
    }
  }, [singleAccount, isOpen])

  const onFinish = (formData: any) => {
    $post({
      url: 'api/account/update',
      send: {
        Id: singleAccount.Id,
        Status: formData.Status,
        Remark: formData.Remark,
        Name: formData.Name
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
      }
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item name="Account" label={i18n.t('backOfficeAccount')} rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="Name" label={i18n.t('nameTitle')} rules={[{ required: true }]}>
          <Input placeholder={`${i18n.t('inputData')}`} maxLength={10} showCount />
        </Form.Item>
        <Form.Item name="Remark" label={i18n.t('description')}>
          <Input placeholder={`${i18n.t('inputData')}`} />
        </Form.Item>
        <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
          <Select
            className="w-12"
            placeholder={`${i18n.t('pleaseSelect')}`}
            options={enumToOptions(PERMISSION_STATE)}
          />
        </Form.Item>
        <Form.Item>
          <Row justify="center" gutter={[12, 12]}>
            <Col>
              <Button className="mt-1" key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
            </Col>
            <Col>
              <Button className="mt-1" key="confirm" htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export const PopupBackOfficeAccountPassword = ({ isOpen, close, singleAccount, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (singleAccount && isOpen) {
      form.setFieldsValue({
        account: singleAccount.Account,
      })
    }
  }, [singleAccount, isOpen])

  const onFinish = (formData: any) => {
    if (formData.password !== formData.confirmNewPassword) {
      return message.error(i18n.t('INCORRECT_REPEAT_PASSWORD'))
    }
    $post({
      url: 'api/account/password/reset',
      send: {
        Id: singleAccount.Id,
        // Name: singleAccount.Account,
        Password: formData.password,
        ConfirmPassword: formData.confirmNewPassword,
        // PermissionId: singleAccount.PermissionId,
        // Status: singleAccount.Status
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onCancel();
        mutate();
      },
      resCode: RESPONSE_CODE_PERMISSION
    })
  }

  const onCancel = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('changePassword')}
      open={isOpen}
      onCancel={onCancel}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="account" label={i18n.t('backOfficeAccount')} rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="password" label={i18n.t('password')} rules={[
          { required: true, message: `${i18n.t('required')}` },
          { min: 4, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
          { max: 20, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
        ]}>
          <Input.Password placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
        </Form.Item>
        <Form.Item name="confirmNewPassword" label={i18n.t('confirmNewPassword')} rules={[
          { required: true, message: `${i18n.t('required')}` },
          { min: 4, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
          { max: 20, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
        ]}>
          <Input.Password placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
        </Form.Item>
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Form.Item>
              <Button className="mt-1" key="cancel" onClick={onCancel}>{i18n.t('cancel')}</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" className="mt-1" key="confirm" type="primary">{i18n.t('confirm')}</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const ViewGameList = ({ data, mutate, selectedRowKeys, setSelectedRowKeys }: {
  data: {
    State: string;
    TotalRecord: number;
    Data: GameType[]
  };
  mutate: () => void;
  selectedRowKeys: any;
  setSelectedRowKeys: any;
}) => {
  const { permissions: $p } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { data: GameUrl } = $get({ url: 'api/systemsetting/info' });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    if (GameUrl) {
      setUrl(GameUrl?.Data.GAME_ICON_URI);
    }
  }, [GameUrl]);

  const handleEditStatus = (Id: number, state: boolean) => {
    $post({
      url: 'api/game/cc/update',
      send: {
        Id,
        Status: state ? 1 : 0,
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_GAME
    }, setIsLoading)
  }

  return (
    <>
      <Table
        size="small"
        scroll={{ x: 1500 }}
        rowSelection={{
          selectedRowKeys,
          getCheckboxProps: (_) => ({
            disabled: !$p('01202')
          }),
          onChange: onSelectChange,
        }}
        dataSource={data?.Data?.map(item => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'FullCode',
            title: i18n.t('ID'),
            width: 300,
            fixed: 'left',
          },
          {
            dataIndex: 'Code',
            title: i18n.t('gameCode'),
            width: 200,
          },
          {
            dataIndex: 'CategoryCode',
            title: i18n.t('gameCategory'),
            width: 200,
            render: (val) => i18n.t(val)
          },
          {
            dataIndex: 'Lang',
            title: i18n.t('gameName'),
            width: 350,
            render: (val, { Name }) => gameNameTransfer(val, Name)
          },
          {
            dataIndex: 'Photo',
            title: i18n.t('Logo'),
            width: 200,
            render: (_, { ProviderCode, Code }) => {
              const UserLang = Cookies.get(COOKIE.LANG);
              const GameLogo = `${url}${UserLang}/${ProviderCode}/${Code}.png`
              return (
                url && <Image src={GameLogo} fallback={systemDefaultGameIcon} width={50} height={50} />
              )
            }
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 150,
            render: (val, record: any) =>
              <Switch checked={val} loading={isLoading} onChange={v => handleEditStatus(record.key, v)} 
                disabled={!$p('01202')} />
          },
          // {
          //   dataIndex: 'IsOpen',
          //   title: `${i18n.t('gameProvider')} ${i18n.t('shelvesDelisting')}`,
          //   width: 150,
          //   align: 'center',
          //   render: (val: number,) => val === 1 ? i18n.t('release') : i18n.t('disable'),
          // },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 200,
            render: (val: string) => timeS2L(val)
          },
        ]}
        pagination={false}
      />
    </>
  )
}

export const PopupAddLicense = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/contents/license/create',
      send: {
        Lang: Cookies.get(COOKIE.LANG),
        Title: formData.title,
        Photo_1: imageData[0] ? imageData[0].url : '',
        LinkURL: formData.link,
        Position: formData.Position,
        Status: formData.status
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_LICENSE
    }, setLoading)
  }

  const onClose = () => {
    close();
    setImageData([]);
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('add')}
      centered
      open={isOpen}
      onCancel={onClose}
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}
        initialValues={{
          status: 1,
          Position: 0,
        }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('title')}
              rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`100${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('image')}
              rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
              <UploadImage
                name="Photo_1"
                url={'/upload/images/public/contents'}
                imageData={imageData}
                setImageData={setImageData}
                form={form}
                w={140}
                h={50}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="link" label={i18n.t('linkURL')}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormInputPosition />
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupEditLicense = ({ isOpen, close, data, mutate }: {
  isOpen: boolean;
  close: () => void;
  data: {
    key: number;
    lang: string;
    sort: number;
    title: string;
    photo: string;
    url: string;
    status: number;
  }
  mutate: () => void;
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        title: data.title,
        link: data.url,
        Position: data.sort,
        status: data.status
      })
      setImageData([
        {
          uid: data.photo,
          name: data.photo,
          url: data.photo,
        },
      ])
    }
  }, [data, isOpen]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/contents/license/update',
      send: {
        Id: data.key,
        Lang: data.lang,
        Title: formData.title,
        Photo_1: imageData ? imageData[0].url : data.photo,
        LinkURL: formData.link,
        Position: formData.Position,
        Status: formData.status
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_LICENSE
    }, setLoading)
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      centered
      open={isOpen}
      onCancel={onClose}
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish} >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('title')}
              rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`100${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('image')}
              rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]} required>
              <UploadImage
                name="Photo_1"
                url={'/upload/images/public/contents'}
                preUrl={data ? data.photo : ''}
                imageData={imageData}
                setImageData={setImageData}
                form={form}
                w={140}
                h={50}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="link" label={i18n.t('linkURL')}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormInputPosition />
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 維護新增編輯
export const PopupMaintenance = ({ isOpen, close, data, mutate, type }: any) => {
  const { data: site } = useSite();
  const [form] = useForm();
  const [date, setDate] = useState<string[]>([]);
  const { data: ProviderOptions, isValidating } = $get({ url: 'api/provider/open/list' });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        provider: null,
        dateRange: [
          dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          ''
        ]
      })
      setDate([
        dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ''
      ])
    }
    if (data && isOpen) {
      form.setFieldsValue({
        provider: Number(data.GameProviderId),
        dateRange: [timeS2L(data.StartDate), timeS2L(data.EndDate), '']
      })
      setDate([timeS2L(data.StartDate), timeS2L(data.EndDate), '']);
    }
  }, [data, isOpen])

  const onFinish = (formData: any) => {
    const send: any = {
      ProviderId: formData.provider,
      StartDate: timeL2S(date[0]),
      EndDate: timeL2S(date[1])
    }
    if (!data) {
      $post({
        url: 'api/providermaintenance/create',
        send,
        success: () => {
          message.success(i18n.t('addSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE
      }, setIsLoading)
    } else {
      $post({
        url: 'api/providermaintenance/update',
        send: {
          Id: data?.key,
          ProviderId: formData.provider,
          StartDate: timeL2S(date[0]),
          EndDate: timeL2S(date[1])
        },
        success: () => {
          message.success(i18n.t('editSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE
      }, setIsLoading)
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={!data ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={420}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="provider" label={`${i18n.t('gameProvider')}／${i18n.t('mainCategory')}`}
          rules={[{ required: true }]}>
          <Select
            showSearch
            filterOption={selectFuzzyFilter}
            loading={isValidating}
            placeholder={i18n.t('pleaseSelect')}
            options={
              ProviderOptions?.Data.map((item: ProviderType) => (
                { value: item.Id, label: `${specialProviderName(item.ProviderName, site.SiteName, item.ProviderCode)}${i18n.t(item.CategoryCode)}` }
              ))
            }
          />
        </Form.Item>
        <Form.Item className="mt-1" label={i18n.t('maintenanceTime')} rules={[{ required: true }]}>
          <Row gutter={[0, 12]}>
            <DatePickerCol width={186} name="dateRange" date={date} setDate={setDate} displayCom={['Picker']} />
          </Row>
        </Form.Item>
        <Row className="mt-1" justify="center" gutter={[12, 12]}>
          <Col>
            <Button onClick={onClose}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 棄用
export const PopupDeleteMaintenance = ({ isOpen, close, id, mutate }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = () => {
    $post({
      url: 'api/providermaintenance/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_MAINTENANCE
    }, setIsLoading)
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" onClick={onClose}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" type="primary" onClick={onDelete}
              loading={isLoading}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
}
