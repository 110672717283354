import { message } from 'antd';
import { RESPONSE_CODE } from 'constants/response';
import i18n from 'i18n';
import QueryString from 'qs';
import useSWR from 'swr';
import Cookies from "js-cookie";
import { COOKIE } from "constants/cookie";
import { useEffect, useRef, useState } from 'react';

const requestInformation = (data: any) => {
  const query = QueryString.stringify(data, { addQueryPrefix: true });
  const token = Cookies.get(COOKIE.TOKEN);
  const headers: any = {
    'Content-Type': 'application/json'
  }
  if (token) headers.Authorization = `${token}`;
  return {
    query,
    headers,
    token
  }
}

// 回應狀態碼判斷
const responseProcessing = async (response: any) => {
  const body = await response.text();
  const bodyObject = JSON.parse(body)
  const res: any = body ? bodyObject : {};
  return res;
}

// 錯誤訊息及處理
const errorAlert = (errorCode: string, resCode: any = {}, showMessage: boolean) => {
  
  // 白名單
  if (errorCode === 'WHITEIP_FORBIDDEN') {
    window.location.href = '/#/error';
    return;
  }
  // 沒有權限
  else if (errorCode === 'PERMISSION_DENIED') {
    window.location.href = '/#/';
    return;
  }
  else if (errorCode === 'UNAUTHORIZATION') {
    window.location.href = '/#/login/UNAUTHORIZATION';
    Cookies.remove(COOKIE.TOKEN);
    return;
  }
  else if (errorCode === 'ACCOUNT_BLOCKED') {
    if (window.location.hash.includes('#/login')) {
      if (showMessage) message.error(RESPONSE_CODE['ACCOUNT_BLOCKED']);
      return;
    } else {
      window.location.href = '/#/login/ACCOUNT_BLOCKED';
      Cookies.remove(COOKIE.TOKEN);
      return;
    }
  }

  const codeI18n: any = {
    ...RESPONSE_CODE,
    ...resCode
  }

  if (showMessage) message.error(codeI18n[errorCode] || i18n.t('incorrectRequest'));
}

type Swr = {
  data?: any;
  isValidating?: boolean;
  mutate?: any;
}

// 有swr
export const $get = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
}): Swr => {

  const { query, headers, token } = requestInformation(params);

  const _swr = useSWR(allow && ((auth && token) || (!auth)) ? `/${url}${query}` : null, async () => {
    return fetch(`/${url}${query}`, {
      method: 'GET',
      credentials: 'omit',
      headers
    })
      .then(async response => {
        const res = await responseProcessing(response);
        // 成功
        if (res.State === "Success") {
          success(res);
          const result: any = {
            Data: res,
          }
          return result.Data;

        // 失敗(通用)
        } else {
          errorAlert(res.Message, resCode, showMessage);
          fail(res);

          const result: any = {
            Data: [],
          }
          return result.Data;
        }
      })

  }, { revalidateOnFocus: false });

  // 啟用過的useSWR會預設回應 { data: [] } 造成錯誤白畫面
  // 如還沒get資料前應回應空集合，使data?.Data有效
  return Array.isArray(_swr?.data) && _swr?.data.length === 0 ? {} : _swr
}

// 沒有swr
export const $fetchGet = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
}): Swr => {

  const { query, headers, token } = requestInformation(params);

  const [data, setData] = useState();
  const [isValidating, setIsValidating] = useState(false);
  const [timestamp, setTimestamp] = useState<number>();

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      get();
    }
  }, []);

  useEffect(() => {
    if (timestamp) {
      get();
    }
  }, [timestamp]);

  function get() {
    if (allow && ((auth && token) || (!auth))) {
      setIsValidating(true);

      fetch(`/${url}${query}`, {
        method: 'GET',
        credentials: 'omit',
        headers
      })
      .then(async response => {
        const res = await responseProcessing(response);
        // 成功
        if (res.State === "Success") {
          success(res);
          const result: any = {
            Data: res,
          }
          setData(result.Data);
    
        // 失敗(通用)
        } else {
          errorAlert(res.Message, resCode, showMessage);
          fail(res);
          const result: any = {
            Data: [],
          }
          setData(result.Data);
        }
  
        setIsValidating(false);
      })
    }
  }

  return {
    data,
    isValidating,
    mutate: () => setTimestamp(new Date().getTime())
  }
}

export const $post = async (
  {
    url,
    send = {},
    showMessage = true,
    resCode = {},
    queryHeader = false,
    success = (data: any) => {},
    fail = (data: any) => {},
  }: any,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  const res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method: 'POST',
    credentials: 'omit',
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (res.State === "Success") {
    success(res);
  } else {
    errorAlert(res.Message, resCode, showMessage);
    fail(res);
  }

  setLoading(false);
}
