import { Button, Col, Form, Input, Modal, Row, Spin, Table, Tooltip, message, Switch, Select, Image, Space, } from "antd";
import { useForm } from 'antd/lib/form/Form';
import { RESPONSE_CODE_MAIL, RESPONSE_CODE_MESSAGE_TEMPLATE_STATUS } from "constants/response";
import { MESSAGE_STATE, MESSAGE_TEMPLATE_STATE } from "enum/state";
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { enumToOptions, timeS2L } from "../utils/common";
import { InquiryWindow, LayoutTextArea } from "./layout.component";
import useAccount from 'hooks/account.hook';
import { MESSAGE_TEMPLATE_TYPE, MESSAGE_TEMPLATE_TYPE_STATUS} from "constants/message-template"
import hintIcon from "assets/image/icon-12.png";

// 即時訊息列表
export const ViewMessageList = ({ data, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const [id, setId] = useState<number | undefined>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case MESSAGE_STATE.notRead:
        return 'color-reject'
      case MESSAGE_STATE.alreadyRead:
        return 'color-pass'
      default: return ''
    }
  }

  const handleInfo = (key: number) => {
    setIsOpenModal(true);
    setId(key);
  };

  return (
    <>
      <Row className="mb-1">
        {
          $p('11002') &&
          <Col span={24}>
            <Button type="primary" onClick={() => handleInfo(0)}>
              {i18n.t('add')}
            </Button>
          </Col>
        }
      </Row>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Title',
            title: i18n.t('subject'),
            width: 300,
            fixed: 'left',
            render: (val) => (
              <Tooltip title={val}>
                <div style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden', textOverflow: 'ellipsis',
                }}>{val}</div>
              </Tooltip>
            )
          },
          {
            dataIndex: 'Content',
            title: i18n.t('content'),
            width: 400,
            render: (val) => (
              <Tooltip title={val}>
                <div style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden', textOverflow: 'ellipsis',
                }}>{val}</div>
              </Tooltip>
            )
          },
          {
            dataIndex: 'SenderAccount',
            title: i18n.t('creator'),
            width: 200,
            render: (val) => val || 'System'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createAndSendTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'IsRead',
            title: i18n.t('status'),
            width: 100,
            render: (val) => <div className={stateColor(val)}>{i18n.t(MESSAGE_STATE[val])}</div>
          },
          {
            dataIndex: 'ReadTime',
            title: i18n.t('readTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record: any) => (
              <Button className="size-12" type="link" onClick={() => handleInfo(record.key)}>{i18n.t('detail')}</Button>
            )
          },
        ]}
        pagination={false}
      />
      <PopupMessage isOpen={isOpenModal} close={() => setIsOpenModal(false)} mutate={mutate} id={id} />
    </>
  )
}

// 新增/詳情即時訊息
export const PopupMessage = ({ isOpen, close, mutate, id }: any) => {
  const [form] = useForm();
  const { data: mailInfo, isValidating } = $get({
    url: 'api/mail/one',
    params: { Id: id },
    allow: !!id
  })

  useEffect(() => {
    if (mailInfo && isOpen) {
      form.setFieldsValue({
        title: mailInfo.Data.Title,
        content: mailInfo.Data.Content,
        recipient: mailInfo.Data.MemberAccount
      })
    }
  }, [mailInfo, isOpen]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/mail/create',
      send: {
        Account: formData.recipient,
        Title: formData.title,
        Content: formData.content
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_MAIL
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={!id ? i18n.t('add') : i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Spin spinning={isValidating}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <LayoutTextArea formName='title' formLabel={i18n.t('subject')} formRules={[{
                required: true, type: 'string', max: 200
              }]} maxLength={200} disabled={!!id} />
            </Col>
            <Col span={24}>
              <LayoutTextArea formName='content' formLabel={i18n.t('content')} formRules={[{
                required: true, type: 'string', max: 200
              }]} maxLength={200} disabled={!!id} />
            </Col>
            <Col span={24}>
              <Form.Item name="recipient" label={i18n.t('recipient')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} disabled={!!id} />
              </Form.Item >
            </Col>
            <Col span={24}>
              <Row justify="center" className="mt-1" gutter={[12, 12]}>
                <Col>
                  <Button key="cancel" onClick={onClose}>{i18n.t('close')}</Button>
                </Col>
                {
                  !id &&
                  <Col>
                    <Button key="confirm" htmlType="submit" type="primary">{i18n.t('sendMessage')}</Button>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}

// 訊息模板列表
export const ViewMessageTemplateList = ({ data, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const [isEdit, setIsEdit] = useState(false)
  const [type, setType] = useState<string>("");
  const [typeStatus, setTypeStatus] = useState<string>("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);


  const handleSettingOrEdit = (boolean: boolean, type: string, typeStatus:string) => {
    setIsOpenModal(true);
    setIsEdit(boolean)
    setType(type);
    setTypeStatus(typeStatus)
  };

  const handleDelete = (type: string, typeStatus:string) => {
    setIsOpenDelete(true);
    setType(type);
    setTypeStatus(typeStatus)
  };

  const onEditStatus = (type: string, typeStatus: string, state: boolean) => {
    $post({
      url: 'api/mail/setting/status/update',
      send: {
        Type: type,
        TypeStatus: typeStatus,
        Status: state ? 1 : 0
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MESSAGE_TEMPLATE_STATUS
    })
  }

  const handleSubmit = (formData: any) => {
    $post({
      url: 'api/mail/setting/delete',
      send: {
        Type: type,
        TypeStatus: typeStatus,
      },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MESSAGE_TEMPLATE_STATUS
    })
  };

  return (
    <>
      <Row className="mb-1">
        {
          $p('11502') &&
          <Col span={24}>
            <Button type="primary" onClick={() => handleSettingOrEdit(false,"","")}>
              {i18n.t('setting')}
            </Button>
          </Col>
        }
      </Row>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Type',
            title: i18n.t('type'),
            width: 200,
            render: (val: any) => i18n.t(MESSAGE_TEMPLATE_TYPE[val])
          },
          {
            dataIndex: 'TypeStatus',
            title: i18n.t('typeStatus'),
            width: 100,
            render: (val: any) => i18n.t(MESSAGE_TEMPLATE_TYPE_STATUS[val])
          },
          {
            dataIndex: 'Title',
            title: i18n.t('subject'),
            width: 300,
            render: (val) => (
              <Tooltip title={val}>
                <div style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden', textOverflow: 'ellipsis',
                }}>{val}</div>
              </Tooltip>
            )
          },
          {
            dataIndex: 'Content',
            title: i18n.t('content'),
            width: 400,
            render: (val) => (
              <Tooltip title={val}>
                <div style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden', textOverflow: 'ellipsis',
                }}>{val}</div>
              </Tooltip>
            )
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 120,
            render: (val: any, { Type, TypeStatus}: any) => {
              return(
                <Switch checked={val} onClick={e => onEditStatus(Type, TypeStatus, e)} disabled={!$p('11503')}/>
              )}
          },
          {
            dataIndex: 'LastModifyAccount',
            title: i18n.t('updater'),
            width: 200,
            render: (val) => val || 'System'
          },
          {
            dataIndex: 'UpdateDate',
            title: i18n.t('updateTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createAndSendTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (val, { Type, TypeStatus }: any) => (
              <>
                {
                $p('11503') &&
                <Button className="size-12" type="link" onClick={() => handleSettingOrEdit(true, Type, TypeStatus)}>{i18n.t('edit')}
                </Button>
                }
                {
                  $p('11504') &&
                  <Button className="size-12" type="link" onClick={() => {
                    handleDelete(Type, TypeStatus)
                  }}>{i18n.t('delete')}
                  </Button>
                }
              </>
            )
          },
        ]}
        pagination={false}
      />
      <PopupMessageTemplate isOpen={isOpenModal} close={() => setIsOpenModal(false)} mutate={mutate} isEdit={isEdit} type={type} typeStatus={typeStatus}/>
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={handleSubmit} />
    </>
  )
}

// 設定訊息模板彈窗/編輯訊息模板彈窗
export const PopupMessageTemplate = ({ isOpen, close, mutate, isEdit, type, typeStatus}: any) => {
  const [form] = useForm();
  const { data: messageTemplateInfo, isValidating, mutate: messageTemplateInfoMutate } = $get({
    url: 'api/mail/setting/one',
    params: {
      Type: type,
      TypeStatus: typeStatus
    },
    allow: isEdit
  })

  const { data: typeList } = $get({
    url: 'api/mail/setting/types'
  })

  useEffect(() => {
    if (messageTemplateInfo && isOpen) {
      form.setFieldsValue({
        type: messageTemplateInfo.Data.Type,
        typeStatus: messageTemplateInfo.Data.TypeStatus,
        title: messageTemplateInfo.Data.Title,
        content: messageTemplateInfo.Data.Content,
        status: messageTemplateInfo.Data.Status
      })
    }
  }, [messageTemplateInfo, isOpen]);

  const onFinish = (formData: any) => {
    $post({
      url: isEdit? 'api/mail/setting/update' : 'api/mail/setting/upsert',
      send: {
        Type: formData.type,
        TypeStatus: formData.typeStatus,
        Title: formData.title,
        Content: formData.content,
        Status: formData.status
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onClose();
        mutate();
        messageTemplateInfoMutate();
      }
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('setting')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Spin spinning={isValidating}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
            <Form.Item  name="type" label={i18n.t('type')} rules={[{ required: true }]}>
              <Select options={[
                ...(typeList?.Data?.Types?.map((item: any) => (
                  { value: item, label: i18n.t(MESSAGE_TEMPLATE_TYPE[item]) }
                )) || [])
              ]} placeholder={`${i18n.t('pleaseSelect')}`} disabled={isEdit}/>
            </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item  name="typeStatus" label={i18n.t('typeStatus')} rules={[{ required: true }]}>
                <Select options={[
                  ...(typeList?.Data?.Status?.map((item: any) => (
                    { value: item, label: i18n.t(MESSAGE_TEMPLATE_TYPE_STATUS[item])}
                  )) || [])
                ]} placeholder={`${i18n.t('pleaseSelect')}`} disabled={isEdit}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <LayoutTextArea formName='title' formLabel={i18n.t('subject')} formRules={[{
                required: true, type: 'string', max: 200
              }]} maxLength={200}  />
            </Col>
            <Col span={24}>
              <LayoutTextArea formName='content' formLabel={i18n.t('content')} formRules={[{
                required: true, type: 'string', max: 200
              }]} maxLength={200}  />
            </Col>
            <Col span={24}>
              <Form.Item  name="status" label={i18n.t('status')} rules={[{ required: true }]}>
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    ...enumToOptions(MESSAGE_TEMPLATE_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" className="mt-1" gutter={[12, 12]}>
                <Col>
                  <Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                {
                  <Col>
                    <Button key="confirm" htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}

// 棄用
export const PopupDeleteMessageTemplate = ({ isOpen, close, type, typeStatus, mutate }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/mail/setting/delete',
      send: {
        Type: type,
        TypeStatus: typeStatus,
      },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        close();
        mutate();
      },
      resCode: RESPONSE_CODE_MESSAGE_TEMPLATE_STATUS
    })
  };

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={onFinish}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}
