import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Divider, Row, Table, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { MEMBER_LOG } from 'enum/cashlog';
import i18n from 'i18n';
import { useEffect, useImperativeHandle, useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeS2L, toFormatNumber as $f } from "utils/common";
import { LayoutPagination } from './layout.component';
import { ENABLE } from "../enum/state";

// 帳戶紀錄: 當日數據

export const ViewAccount = ({ refs, params }: any) => {
  useImperativeHandle(refs, () => ({

    mutate: () => {
      mutateInfo();
      mutateBalance();
    }

  }));
  const [systemAccountData, setSystemAccountData] = useState<AccountInfo>({
    WithdrawTotal: [],
    MainTotal: [],
    BetTotal: []
  });
  const { data: memberWithdrawInfo, mutate: mutateInfo, isValidating } = $get({
    url: 'api/member/withdraw/info', 
    params: { ...params }, 
    allow: !!(params?.Account)
  });
  const { data: memberBalanceInfo, mutate: mutateBalance, isValidating: isValidatingBalance } = $get({
    url: 'api/member/balance/info',
    params: {
      Account: params.Account,
      StartDate: params.StartDate,
      EndDate: params.EndDate
      // 不吃KeyId
    },
    allow: !!(params?.Account),
    showMessage: false
  })
  // const { data: memberBalanceInfo, mutate: mutateBalance, isValidating: isValidatingBalance }: any = GetMemberBalanceInfo({
  //   Account: account,
  //   ...balanceParams,
  // }, !!account);

  useEffect(() => {
    if (memberWithdrawInfo && memberBalanceInfo) {
      setSystemAccountData({
        WithdrawTotal: memberWithdrawInfo.data ? [
          {
            key: 1,
            withDrawMax: memberWithdrawInfo?.data.MemberMaxWithdrawPerDay,
            withDrawPaddingTotal: memberWithdrawInfo?.data.MemberStillWithdrawAmount,
            withDrawProcessingTotal: memberWithdrawInfo?.data.MemberAuditeWithdrawAmount,
            withDrawTotal: memberWithdrawInfo?.data.MemberCompleteWithdrawAmount,
          }
        ] : [],
        MainTotal: memberBalanceInfo.data ? [
          {
            key: 2,
            balance: memberBalanceInfo?.data.Balance,
            shouldValidBetAmount: memberBalanceInfo?.data.ShouldValidBetAmount,

            totalDepositAmount: memberBalanceInfo?.data.TotalDepositAmount,
            totalWithdrawAmount: memberBalanceInfo?.data.TotalWithdrawAmount,
            totalBonusAmount: memberBalanceInfo?.data.TotalBonusAmount,
            increaseTotal: {
              up: memberBalanceInfo?.data.TotalAdjustPlusAmount,
              down: memberBalanceInfo?.data.TotalAdjustMinusAmount,
            }
          }
        ] : [],
        BetTotal: memberBalanceInfo.data ? [
          {
            key: 3,
            winLossAmount: memberBalanceInfo?.data.WinLossAmount,
            validBetAmount: memberBalanceInfo?.data.ValidBetAmount,
            totalCommissionAmount: memberBalanceInfo?.data.TotalCommissionAmount,
          }
        ] : [],
      })
    }
  }, [memberWithdrawInfo, memberBalanceInfo])

  return (
    <Row className="mt-1">
      <Col span={24}>
        <div className="size-16 font-w-sm mb-1">
          {`${dayjs().format('MM-DD')} ${i18n.t('dailyData')}`}
        </div>
        <Table
          loading={isValidating}
          size="middle"
          dataSource={systemAccountData.WithdrawTotal || []}
          columns={[
            {
              dataIndex: 'withDrawMax',
              title: i18n.t('withdrawalLimit'),
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            },
            {
              dataIndex: 'withDrawPaddingTotal',
              title: i18n.t('todayRemainingWithdrawal'),
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            },
            {
              dataIndex: 'withDrawProcessingTotal',
              title: i18n.t('withdrawalInProgress'),
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            },
            {
              dataIndex: 'withDrawTotal',
              title: i18n.t('todayWithdrawn'),
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            }
          ]}
          pagination={false}
        />
      </Col>
      <Col span={24}><Divider /></Col>
      {/* 總餘額 */}
      <Col span={24}>
        <Table
          loading={isValidatingBalance}
          size="middle"
          dataSource={systemAccountData.MainTotal || []}
          columns={[
            {
              title: i18n.t('balance'),
              dataIndex: 'balance',
              align: "right",
              width: '20%',
              render: (_, { balance }: any) => (
                <div className={common.numColor01(balance)}>{$f(balance)}</div>
              )
            },
            {
              title: i18n.t('expectedValidBet'),
              dataIndex: 'shouldValidBetAmount',
              align: "right",
              width: '20%',
              render: (_, { shouldValidBetAmount }) => (
                <div className={common.numColor01(shouldValidBetAmount)}>{$f(shouldValidBetAmount)}</div>
              )
            },
          ]}
          pagination={false}
        />
      </Col>
      <Col span={24}><Divider /></Col>
      <Col span={24}>
        <Table
          loading={isValidatingBalance}
          size="middle"
          dataSource={systemAccountData.MainTotal || []}
          columns={[
            {
              title: i18n.t('totalDepositSum'),
              dataIndex: 'totalDepositAmount',
              align: "right",
              width: '20%',
              render: (_, { totalDepositAmount }: any) => (
                <div className={common.numColor01(totalDepositAmount)}>{$f(totalDepositAmount)}</div>
              )
            },
            {
              title: i18n.t('totalWithdrawAmount'),
              dataIndex: 'totalWithdrawAmount',
              align: "right",
              width: '20%',
              render: (_, { totalWithdrawAmount }) => (
                <div className={common.numColor01(totalWithdrawAmount)}>{$f(totalWithdrawAmount)}</div>
              )
            },
            {
              title:
                <>
                  {i18n.t('totalPromotion')}
                  <Tooltip placement="top" className="size-12" title={i18n.t('IncludeAllPromotionalAdjustments')}>
                    <InfoCircleFilled />
                  </Tooltip>
                </>,
              dataIndex: 'totalBonusAmount',
              align: "right",
              width: '20%',
              render: (_, { totalBonusAmount }) => (
                <div className={common.numColor01(totalBonusAmount)}>{$f(totalBonusAmount)}</div>
              )
            },
            {
              title: `${i18n.t('totalAdjustment')}(+／-)`,
              dataIndex: 'increaseTotal',
              align: "right",
              width: '20%',
              render: (_, { increaseTotal }) => (
                <>
                  <span className={common.numColor01(increaseTotal.up)}>{$f(increaseTotal.up)}</span>／
                  <span className={common.numColor01(increaseTotal.down)}>{$f(increaseTotal.down)}</span>
                </>
              )
            },
          ]}
          pagination={false}
        />
      </Col>
      <Col span={24}>
        <Table
          size="middle"
          dataSource={systemAccountData.BetTotal || []}
          columns={[
            {
              title: i18n.t('totalWinLoseAmount'),
              dataIndex: 'winLossAmount',
              align: "right",
              width: '20%',
              render: (_, { winLossAmount }: any) => (
                <div className={common.numColor01(winLossAmount)}>{$f(winLossAmount)}</div>
              )
            },
            {
              title: i18n.t('totalValidBets'),
              dataIndex: 'validBetAmount',
              align: "right",
              width: '20%',
              render: (_, { validBetAmount }) => (
                <div className={common.numColor01(validBetAmount)}>{$f(validBetAmount)}</div>
              )
            },
            {
              title:
                <>
                  {i18n.t('totalRebate')}
                  {/* <Tooltip placement="top" className="size-12" title={i18n.t('IncludeCashbackAdjustments')}>
                    <InfoCircleFilled />
                  </Tooltip> */}
                </>,
              dataIndex: 'totalCommissionAmount',
              align: "right",
              width: '20%',
              render: (_, { totalCommissionAmount }) => (
                <div className={common.numColor01(totalCommissionAmount)}>{$f(totalCommissionAmount)}</div>
              )
            },
            {
              align: "right",
              width: '20%',
            },
          ]}
          pagination={false}
        />
      </Col>
    </Row>
  )
}

export const ViewAccountHistory = ({ params, page, setPage, refs }: any) => {

  useImperativeHandle(refs, () => ({

    mutate: () => {
      mutate()
    }

  }));

  const { data: memberCashlogInfoList, mutate, isValidating } = $get({
    url: 'api/member/cashlog/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1],
    },
    allow: !!params.Account,
    showMessage: false
  });

  return (
    <>
      <Table
        className="mt-1"
        loading={isValidating}
        size="middle"
        dataSource={
          memberCashlogInfoList && memberCashlogInfoList.Data
            ? memberCashlogInfoList?.Data.map((item: any) => ({ ...item, key: item.Id }))
            : []
        }
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('transactionCode'),
            align: 'left',
            width: 300
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('time'),
            width: 150,
            render: (val: string) => timeS2L(val)
          },
          {
            dataIndex: 'KeyId',
            title: i18n.t('transactionType'),
            width: 150,
            render: (val) => MEMBER_LOG[val] ? i18n.t(MEMBER_LOG[val]) : val
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            render: (val, { WalletType }: any) => {
              const formattedVal = WalletType === 2 ? '-' : $f(val);
              return <div className={common.numColor01(val)}>{formattedVal}</div>;
            }
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('previousAmount'),
            align: 'right',
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            render: (val, { WalletType }) => {
              const formattedVal = WalletType === 2 ? '-' : $f(val);
              return <div className={common.numColor01(val)}>{formattedVal}</div>;
            }
          }
        ]}
        pagination={false}
      />
      <LayoutPagination total={memberCashlogInfoList ? memberCashlogInfoList?.TotalRecord : 0}
        page={page} setPage={setPage} />
    </>
  )
}

// 代理標籤 會員標籤
export const TagTableDisplay = ({ ids, list }: any) => {
  const idArray: Number[] = typeof ids === 'string' ? JSON.parse(ids) : ids;
  
  const tagComponent = (id: any) => {
    const tagInfo = list?.Data
      .filter((item: any)=>item.Status === ENABLE.enabled)
      .find((item: any) => item.Id === id);

    return (
      tagInfo 
      ? <Tag 
          key={tagInfo.Id}
          style={{
            color: tagInfo.TextColor,
            background: tagInfo.Color,
            minWidth: 80,
            minHeight: 20,
            textAlign: 'center',
          }}
        >
          {tagInfo.Name}
        </ Tag>
      : <></>
    )
  }

  return (
    <>
      { 
        (list?.Data.length > 0 && idArray.length > 0)
          ? <>{idArray.map((id: any) => tagComponent(id))}</> 
          : '-' 
      }
    </>
  )
}