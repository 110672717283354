import { Button, Col, Form, Image, Input, Modal, Row, Space, Spin, Table, message } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { useForm } from 'antd/lib/form/Form';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { AGENT_ADJUST_STATE, IS_OFFLINE, MODE, TRANSFER_TYPE } from 'enum/state';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { timeS2L, toFormatNumber, verify } from "../utils/common";
import { UploadImage } from './layout.component';
import useAccount from 'hooks/account.hook';
import errorIcon from "assets/image/erroricon.png";

// 列表
export const ViewVerificationList = ({ data, mutate }: {
  data: {
    State: string;
    Data: VerificationList[];
    TotalRecord: number;
  };
  mutate: any
}) => {
  const { permissions: $p } = useAccount();
  const [id, setId] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popMode, setPopMode] = useState(MODE.add);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case AGENT_ADJUST_STATE.approvaled:
        return 'color-pass'
      case AGENT_ADJUST_STATE.reject:
        return 'color-reject'
      case AGENT_ADJUST_STATE.waitApproval:
        return 'color-padding'
      default: return ''
    };
  };

  return (
    <>
      <Row className="mb-1">
        {
          $p('10802') &&
          <Col>
            <Button type="primary" onClick={() => {
              setPopMode(MODE.add);
              setId(0);
              setIsOpenModal(true);
            }}>{i18n.t('add')}</Button>
          </Col>
        }
      </Row>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={
          data ?
            data.Data.map(item => ({
              key: item.Id,
              MemberAccount: item.MemberAccount,
              Name: item.Name,
              CreateDate: item.CreateDate,
              AuditedTime: item.AuditedTime,
              OperatorAccount: item.OperatorAccount,
              Status: item.Status
            })) : []}
        columns={[
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            width: 200,
          },
          {
            dataIndex: 'Name',
            title: i18n.t('realName'),
            width: 200,
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 200,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'AuditedTime',
            title: i18n.t('auditTime'),
            width: 200,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('status'),
            width: 250,
            render: (_, record: any) => (
              <>
                <div className={stateColor(record.Status)}>{i18n.t(AGENT_ADJUST_STATE[record.Status])}</div>
                {
                  (record.Status !== AGENT_ADJUST_STATE.waitApproval) &&
                  <div>{`${i18n.t('from')} ${record.OperatorAccount || 'System'} ${i18n.t(AGENT_ADJUST_STATE[record.Status])}`}</div>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record: any) => <>
              {
                record.Status === AGENT_ADJUST_STATE.waitApproval && $p('10804') &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.review);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('audit')}</Button>
              }
              {
                record.Status === AGENT_ADJUST_STATE.reject && $p('10803') &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.edit);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('edit')}</Button>
              }
              {
                record.Status === AGENT_ADJUST_STATE.approvaled &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.detail);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('detail')}</Button>
              }
            </>
          },
        ]}
        pagination={false}
      />
      <PopupVerificationAccount isOpen={isOpenModal} close={() => setIsOpenModal(false)} mutate={mutate} mode={popMode} id={id} />
    </>
  )
}

// 新增編輯實名驗證
export const PopupVerificationAccount = ({ isOpen, close, mutate, id, mode }: any) => {
  const [form] = useForm();
  const [imageData01, setImageData01] = useState<UploadFile<any>[]>([]);
  const [imageData02, setImageData02] = useState<UploadFile<any>[]>([]);
  const [approve, setApprove] = useState(false);

  const { data: memberData, mutate: mutateOne } = $get({
    url: 'api/kycticket/one',
    params: { Id: id },
    allow: !!id
  });
  useEffect(() => {
    if (isOpen) {
      mutateOne();
    }
  }, [isOpen]);
  useEffect(() => {
    if (memberData && isOpen) {
      form.setFieldsValue({
        Account: memberData.Data.MemberAccount,
        IDNumber: memberData.Data.IDNumber,
        Name: memberData.Data.Name,
        Photo_1: memberData.Data.Photo_1 || null,
        Photo_2: memberData.Data.Photo_2 || null
      })
      setImageData01([{
        uid: memberData.Data.Photo_1,
        name: memberData.Data.Photo_1,
        url: memberData.Data.Photo_1,
      }])
      setImageData02([{
        uid: memberData.Data.Photo_2,
        name: memberData.Data.Photo_2,
        url: memberData.Data.Photo_2,
      }])
    }
  }, [memberData, isOpen]);

  const onFinish = (formData: any) => {
    const send: any = {
      IDNumber: formData.IDNumber,
      Name: formData.Name,
      Photo_1: imageData01[0] ? imageData01[0].url : '',
      Photo_2: imageData02[0] ? imageData02[0].url : '',
      Photo_3: ''
    }
    switch (mode) {
      case MODE.add:
        send.Account = formData.Account;
        $post({
          url: 'api/kycticket/upsert',
          send,
          success: () => {
            message.success(i18n.t('addSuccess'));
            onClose();
            mutate();
          },
          resCode: RESPONSE_CODE_MEMBER
        })
        break;
      case MODE.review:
        $post({
          url: 'api/kycticket/verify',
          send: {
            Id: id,
            Status: approve ? AGENT_ADJUST_STATE.approvaled : AGENT_ADJUST_STATE.reject
          },
          success: () => {
            message.success(approve ? i18n.t('approved') : i18n.t('rejected'))
            onClose();
            mutate();
          },
          resCode: RESPONSE_CODE_MEMBER
        })
        break;
      case MODE.edit:
        send.Id = id;
        $post({
          url: 'api/kycticket/update',
          send,
          success: () => {
            message.success(i18n.t('addSuccess'))
            onClose();
            mutate();
          },
          resCode: RESPONSE_CODE_MEMBER
        })
        break;
    }
  };

  const onClose = () => {
    form.resetFields();
    setImageData01([]);
    setImageData02([]);
    close();
  };

  let title = '';
  switch (mode) {
    case MODE.add:
      title = i18n.t('add');
      break;
    case MODE.review:
      title = i18n.t('audit');
      break;
    case MODE.edit:
      title = i18n.t('edit');
      break;
    case MODE.detail:
      title = i18n.t('detail');
      break;
    default:
      break;
  }

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('account')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('account')}`} disabled={mode === MODE.review || mode === MODE.edit || mode === MODE.detail} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="IDNumber" label={i18n.t('idNum')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('idNum')}`} disabled={mode === MODE.review || mode === MODE.detail} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('name')} rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('name')}`} disabled={mode === MODE.review || mode === MODE.detail} />
            </Form.Item>
          </Col>
          <Col span={24}>
            {(mode === MODE.add || mode === MODE.edit) && <>
              <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('uploadPaperwork')}
                rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
                <UploadImage
                  form={form}
                  name="Photo_1"
                  url={'/upload/images/public/kycticket'}
                  preUrl={memberData ? memberData.Data.Photo_1 : ""}
                  imageData={imageData01}
                  setImageData={setImageData01}
                  remove
                  disabled={mode === MODE.review || mode === MODE.detail}
                  s={'10MB'}
                />
              </Form.Item>
              <Form.Item name="Photo_2" valuePropName="fileList" label={i18n.t('uploadPaperwork')}
                rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
                <UploadImage
                  form={form}
                  name="Photo_2"
                  url={'/upload/images/public/kycticket'}
                  preUrl={memberData ? memberData.Data.Photo_2 : ""}
                  imageData={imageData02}
                  setImageData={setImageData02}
                  remove
                  disabled={mode === MODE.review || mode === MODE.detail}
                  s={'10MB'}
                />
              </Form.Item>
            </>}
            {(mode === MODE.review || mode === MODE.detail) && imageData01[0] && imageData02[0] && <>
              <Row gutter={[12, 0]}>
                <Col span={24}>{i18n.t('uploadPaperwork')}</Col>
                {memberData?.Data.Photo_1 && <Col><Image src={imageData01[0].url} width={180} height={120} /></Col>}
                {memberData?.Data.Photo_2 && <Col><Image src={imageData02[0].url} width={180} height={120} /></Col>}
              </Row>
            </>}
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              {
                (mode === MODE.add || mode === MODE.edit) &&
                <>
                  <Col>
                    <Button onClick={onClose}>{i18n.t(mode === MODE.edit ? 'reject' : 'cancel')}</Button>
                  </Col>
                  <Col>
                    <Button type="primary" htmlType="submit">{i18n.t(mode === MODE.edit ? 'approvaled' : 'confirm')}</Button>
                  </Col>
                </>
              }
              {
                (mode === MODE.detail) &&
                <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
              }
              {
                (mode === MODE.review) && <>
                  <Col><Button type="primary" danger onClick={() => {
                    setApprove(false);
                    form.submit();
                  }}>{i18n.t('reject')}</Button></Col>
                  <Col><Button type="primary" onClick={() => {
                    setApprove(true);
                    form.submit();
                  }}>{i18n.t('approvaled')}</Button></Col>
                </>
              }
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

// 渠道編輯
export const PopupMerchantEdit = ({ isOpen, close, currentItem, mutate }: {
  isOpen: boolean;
  close: any;
  currentItem: Merchant;
  mutate: any;
}) => {
  const [form] = useForm();

  const Type = currentItem.GatewayCode?.includes('WITHDRAW') 
    ? TRANSFER_TYPE.withdrawal
    : TRANSFER_TYPE.deposit

  useEffect(() => {
    if (currentItem && isOpen) {
      Type === TRANSFER_TYPE.withdrawal
      ? form.setFieldsValue({
        Type: i18n.t(IS_OFFLINE[currentItem.IsOffline || 0]),
        Name: `${currentItem.Name} - ${currentItem.CustomName}`,
        OperateTimesPerDay: currentItem.OperateTimesPerDay,
        MaxAmountPerTrans: currentItem.MaxAmountPerTrans
      })
      : form.setFieldsValue({
        Type: i18n.t(IS_OFFLINE[currentItem.IsOffline || 0]),
        Name: `${currentItem.Name} - ${currentItem.CustomName}`,
        OperateTimesPerDay: currentItem.OperateTimesPerDay,
        MinAmountPerTrans: currentItem.MinAmountPerTrans,
        MaxAmountPerTrans: currentItem.MaxAmountPerTrans,
      })
    }
  }, [currentItem, isOpen])

  const onFinish = (formData: any) => {
    Type === TRANSFER_TYPE.withdrawal
    ? $post({
      url: 'api/membergroupmerchant/withdraw/update',
      send: {
        Type,
        MemberGroupId: currentItem.MemberGroupId,
        MerchantId: currentItem.MerchantId,
        OperateTimesPerDay: formData.OperateTimesPerDay || null,
        MaxAmountPerTrans: formData.MaxAmountPerTrans
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
      },
    })
    : $post({
      url: 'api/membergroupmerchant/deposit/update',
      send: {
        Type,
        MemberGroupId: currentItem.MemberGroupId,
        MerchantId: currentItem.MerchantId,
        OperateTimesPerDay: formData.OperateTimesPerDay || null,
        MinAmountPerTrans: formData.MinAmountPerTrans,
        MaxAmountPerTrans: formData.MaxAmountPerTrans
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
      },
    })
  };

  const onClose = () => {
    close();
    form.resetFields();
  }

  const validator: any = async (d: any, val: number, lower: string, upper: string) => {
    const _val: number = Number(val);
    const downerInputValue: number = Number(form.getFieldValue(lower));
    const upperInputValue: number = Number(form.getFieldValue(upper));
    const min: number | undefined = currentItem.MerchantMinAmountPerTrans;
    const max: number | undefined = currentItem.MerchantMaxAmountPerTrans;

    const smallerMsg = (min && min > downerInputValue ) || !downerInputValue ? min : downerInputValue;
    // const largerMsg = (max && max < upperInputValue) || !upperInputValue ? max : upperInputValue;

    // const smallerMsgLv2 = max && max < downerInputValue ? max : smallerMsg;
    // const largerMsgLv2 = min && min > upperInputValue ? min : largerMsg;

    // const downerWord = max && max < downerInputValue ? 'mustBeLessThanOrEqualTo' : 'mustBeGreaterThanOrEqualTo';
    // const upperWord = min && min > upperInputValue ? 'mustBeGreaterThanOrEqualTo' : 'mustBeLessThanOrEqualTo';

    // 需大於等於min
    if (min && _val < min && _val > 0) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${toFormatNumber(smallerMsg, 0)}`}</p>);
    // 需小於等於max
    else if (max && _val > max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')}${toFormatNumber(max, 0)}`}</p>);
    // 需大於等於下限
    else if (_val < downerInputValue && (downerInputValue && downerInputValue !== 0)) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${toFormatNumber(smallerMsg, 0)}`}</p>);
    // 需小於等於上限
    // else if (_val > upperInputValue && (upperInputValue && upperInputValue !== 0)) return Promise.reject(() =>
    //   <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')}${toFormatNumber(upperInputValue, 0)}`}</p>);

    else return Promise.resolve();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      forceRender
      footer={
        <Row justify="center" gutter={[12, 12]} className="mt-1">
          <Col><Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button></Col>
          <Col><Button key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>

          <Col span={24}>
            <Form.Item name="Type" label={i18n.t('type')} required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={`${i18n.t('channelName')} - ${i18n.t('displayName')}`} required>
              <Input disabled />
            </Form.Item>
          </Col>

          {/* 出款欄位 */}
          {Type === TRANSFER_TYPE.withdrawal && <>
          <Col span={24}>
            <Form.Item name="OperateTimesPerDay" label={i18n.t('MaximumWithdrawalsPerDay')}
              rules={[...verify({ point: 0, zero: false, required: false })]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="MaxAmountPerTrans" label={i18n.t('MaximumWithdrawalSingle')}
              rules={[
                ...verify({ point: 4, zero: false }),
                { validator: (d, val) => validator(d, val, null, null) }
              ]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          </>}

          {/* 入款欄位 */}
          {Type === TRANSFER_TYPE.deposit && <>
          <Col span={24}>
            <Form.Item name="OperateTimesPerDay" label={i18n.t('MaximumDepositPerDay')} 
              rules={[...verify({ point: 0, zero: false, required: false })]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="MinAmountPerTrans" label={i18n.t('MinimumDepositSingle')}
              rules={[
                ...verify({ point: 4, zero: false }), 
                { validator: (d, val) => validator(d, val, null, 'MaxAmountPerTrans') }
              ]}>
              <Input placeholder={`${i18n.t('inputData')}`} onChange={() => form.validateFields(['MaxAmountPerTrans'])} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="MaxAmountPerTrans" label={i18n.t('MaximumDepositSingle')}
              rules={[
                ...verify({ point: 4, zero: false }),
                { validator: (d, val) => validator(d, val, 'MinAmountPerTrans', null) }
              ]}>
              <Input placeholder={`${i18n.t('inputData')}`} onChange={() => form.validateFields(['MinAmountPerTrans'])} />
            </Form.Item>
          </Col>
          </>}

        </Row>
      </Form>
    </Modal>
  )
}

export const PopupAAA = ({ isOpen, close, memberGroupName }: any) => {
  return (
    <Modal
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={errorIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{`${i18n.t('error')}`}</Col>
        </Row>
      }
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" className="mt-1" key="cancel" onClick={close}>
              {i18n.t('close')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{`${i18n.t('pleaseTransfer')} ${memberGroupName} ${i18n.t('membersToDeactivate')}`}</div>
      </Space>
    </Modal>
  )
}