import PageAdvanced from "pages/admin/advanced";
import PageAdvancedAddAnnouncement from "pages/admin/advanced/add_announcement";
import PageAdvancedAddSystemAnnouncement from "pages/admin/advanced/add_system_announcement";
import PageAdvancedAnnouncement from "pages/admin/advanced/announcement";
import PageAdvancedSystemAnnouncement from "pages/admin/advanced/announcement_system";
import PageAdvancedEditAnnouncement from "pages/admin/advanced/edit_announcement";
import PageAdvancedEditSystemAnnouncement from "pages/admin/advanced/edit_system_announcement";
import PageAdvancedGameManagement from "pages/admin/advanced/game";
import PageAdvancedLoginRecord from "pages/admin/advanced/login-record";
import PageAdvancedMaintenance from "pages/admin/advanced/maintenance";
import PageAdvancedOperation from "pages/admin/advanced/operation";
import PageAdvancedOperationNew from "pages/admin/advanced/operation_new";
import PageAdvancedPermissionBackOfficeAccount from "pages/admin/advanced/permission_backOfficeAccount";
import PageAdvancedPermissionName from "pages/admin/advanced/permission_name";
import PageAdvancedPermissionNameEdit from "pages/admin/advanced/permission_name_edit";
import PageAdvancedGameData from "pages/admin/advanced/platform_gameData";
import PageAdvancedGameList from "pages/admin/advanced/platform_gameList";
import PageAdvancedGameRebate from "pages/admin/advanced/platform_gameRebate";
import PageAdvancedLicense from "pages/admin/advanced/platform_license";
import PageAdvancedProviderSort from "pages/admin/advanced/platform_providerSort";
import PageAdvancedCustomerServiceSoftwareSetting from "pages/admin/advanced/platform_customerServiceSoftwareSetting";

export const advancedModule = [
  { path: '/advanced', Component: PageAdvanced },
  { path: '/advanced/platform_gameData', Component: PageAdvancedGameData },
  { path: '/advanced/platform_providerSort', Component: PageAdvancedProviderSort },
  { path: '/advanced/platform_gameList', Component: PageAdvancedGameList },
  { path: '/advanced/platform_gameRebate', Component: PageAdvancedGameRebate },
  { path: '/advanced/platform_customerServiceSoftwareSetting', Component: PageAdvancedCustomerServiceSoftwareSetting },
  { path: '/advanced/permission_name', Component: PageAdvancedPermissionName },
  { path: '/advanced/permission_name_edit/:id', Component: PageAdvancedPermissionNameEdit },
  { path: '/advanced/permission_backOfficeAccount/:id?', Component: PageAdvancedPermissionBackOfficeAccount },
  { path: '/advanced/announcement', Component: PageAdvancedAnnouncement },
  { path: '/advanced/announcement_system', Component: PageAdvancedSystemAnnouncement },
  { path: '/advanced/maintenance', Component: PageAdvancedMaintenance },
  { path: '/advanced/operation', Component: PageAdvancedOperation },
  { path: '/advanced/operation_new', Component: PageAdvancedOperationNew },
  { path: '/advanced/announcement/add', Component: PageAdvancedAddAnnouncement },
  { path: '/advanced/announcement/edit/:id', Component: PageAdvancedEditAnnouncement },
  { path: '/advanced/announcement/system/add', Component: PageAdvancedAddSystemAnnouncement },
  { path: '/advanced/announcement/system/edit/:id', Component: PageAdvancedEditSystemAnnouncement },
  { path: '/advanced/platform_license', Component: PageAdvancedLicense },
  { path: '/advanced/login-record', Component: PageAdvancedLoginRecord },
  { path: '/advanced/game/management', Component: PageAdvancedGameManagement },
]