import { AGENT_LOG } from "enum/cashlog";
import { COUPON_EXPORT_STATUS } from "enum/coupon";
import { SETTLE_CYCLE } from "enum/promotion";
import {
  ADJUST_TYPE,
  AGENT_ADJUST_STATE,
  AGENT_STATE,
  DEPOSIT_STATE,
  MEMBER_FREEZE,
  MEMBER_STATE,
  PROMOTION_REBATE_STATE,
  REPORT_STATE,
  WITHDRAW_STATE,
} from "enum/state";
import i18n from "i18n";
import * as common from 'utils/common';
import { convertedToPercentage01 as $g, timeS2L, toFormatNumber as $f } from "utils/common";
import { GATEWAY_CODE_DEPOSIT, GATEWAY_CODE_WITHDRAW } from "./gateway";
import { CASHBACK_TYPE } from "../enum/rebate";

// 其實render跟excelRender都可以用

export const ExcelColumns = {
  // 會員列表
  MemberList: [
    {
      title: i18n.t('memberAccount'),
      dataIndex: "Account",
      excelRender: (text: string, record: any, index: number) => text
    },
    {
      title: i18n.t('promotionCode'),
      dataIndex: "RefCode",
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: "UPMemberAccount",
    },
    {
      title: i18n.t('agent'),
      dataIndex: "AGAccount",
    },
    {
      title: i18n.t('status'),
      dataIndex: 'IsFreeze',
      excelRender: (text: number) => i18n.t(MEMBER_FREEZE[text ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal]),
    },
    {
      title: i18n.t('audit'),
      dataIndex: 'Status',
      excelRender: (text: number) => i18n.t(MEMBER_STATE[text]),
    },
    {
      title: i18n.t('memberLevel'),
      dataIndex: 'MemberLevelName',
    },
    {
      title: i18n.t('createTime'),
      dataIndex: "CreateDate",
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('IP'),
      dataIndex: "RegisterIP",
    },
    {
      title: i18n.t('lastLoginTime'),
      dataIndex: "LastLoginDate",
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('IP'),
      dataIndex: "IP",
    },
    {
      title: i18n.t('lastDepositTime'),
      dataIndex: "LastDepositTime",
      render: (val: any) => common.timeS2L(val)
    },
    {
      dataIndex: 'LastDepositGatewayCode',
      title: i18n.t('depositMethod'),
      render: (val: any) => i18n.t(val) || '-'
    },
    {
      title: i18n.t('memberTag'),
      excelRender: (_: any, record: any) => {
        const Tags = JSON.parse(record.TagsId);
        return (
          Tags.length > 0
            ? record.otherData?.Data?.filter((item: any) => Tags.includes(item.Id)).map((item: any) => item.Name).join(',')
            : '-'
        )
      }
    },
    {
      title: i18n.t('remark'),
      dataIndex: "RemarkFromAccount",
    },
  ],
  // 總代列表
  AgentList: [
    {
      title: i18n.t('account'),
      dataIndex: 'Account',
    },
    {
      title: i18n.t('agentTag'),
      excelRender: (_: any, record: any) => {
        const Tags = JSON.parse(record.TagsId);
        return (
          Tags.length > 0
            ? record.otherData?.Data?.filter((item: any) => Tags.includes(item.Id)).map((item: any) => item.Name).join(',')
            : '-'
        )
      }
    },
    {
      title: i18n.t('promotionCode'),
      dataIndex: 'RefCode',
    },
    {
      title: i18n.t('teamAgentCount'),
      dataIndex: 'DownlineAgentCount',
      excelRender: (_: any, record: Agent) => (
        $f((record?.DownlineAgentCount || 0) + (record?.InDownlineAgentCount || 0), 0)
      )
    },
    {
      title: i18n.t('teamMemberCount'),
      dataIndex: 'DownlineMemberCount',
      excelRender: (_: any, record: Agent) => (
        $f((record?.DownlineMemberCount || 0) + (record?.InDownlineMemberCount || 0), 0)
      )
    },
    {
      title: i18n.t('walletBalance'),
      dataIndex: 'Balance',
      excelRender: (Balance: number) => $f(Balance)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => i18n.t(`${AGENT_STATE[Status]}`)
    },
    {
      title: i18n.t('lastLoginTime'),
      dataIndex: 'LastLoginDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('IP'),
      dataIndex: 'IP',
    },
    {
      title: i18n.t('rebate'),
      dataIndex: 'CommissionSettleCycle',
      render: (CommissionSettleCycle: number) => i18n.t(SETTLE_CYCLE[CommissionSettleCycle])
    },
    {
      title: i18n.t('revenue'),
      dataIndex: 'RevenueShareSettleCycle',
      render: (RevenueShareSettleCycle: number) => i18n.t(SETTLE_CYCLE[RevenueShareSettleCycle])
    }
  ],
  // 財務-調帳查詢
  AdjustList: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 30,
      },
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'Account',
    },
    {
      title: i18n.t('memberLevel'),
      dataIndex: 'MemberLevelName',
    },
    {
      title: i18n.t('memberGroup'),
      dataIndex: 'MemberGroupName',
    },
    {
      title: i18n.t('transactionType'),
      dataIndex: 'Type',
      excelRender: (val: number) => i18n.t(ADJUST_TYPE[val])
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('amount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('rolloverRate'),
      dataIndex: 'ValidBetMultiple',
    },
    {
      title: i18n.t('expectedValidBet'),
      dataIndex: 'expectedValidBet',
      excelRender: (_: any, record: any) => $f(parseFloat(record.Amount as any) * parseFloat(record.ValidBetMultiple as any))
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => i18n.t(AGENT_ADJUST_STATE[Status])
    },
  ],
  // 統計報表-輸贏報表
  ReportWinLose: [
    {
      title: i18n.t('date'),
      dataIndex: 'ReportDate'
    },
    {
      title: i18n.t('betCounts'),
      dataIndex: 'TotalBetCount',
    },
    {
      title: i18n.t('betAmount'),
      dataIndex: 'TotalBetAmount',
      excelRender: (TotalBetAmount: number) => common.toFormatNumber(TotalBetAmount)
    },
    {
      title: i18n.t('payout'),
      dataIndex: 'TotalSettleAmount',
      excelRender: (TotalSettleAmount: number) => common.toFormatNumber(TotalSettleAmount)
    },
    {
      title: i18n.t('totalWinLossAmounts'),
      dataIndex: 'TotalWinLossAmount',
      excelRender: (TotalWinLossAmount: number) => common.toFormatNumber(TotalWinLossAmount)
    },
  ],
  // 代理錢包紀錄
  AgentWalletRecord: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    // 現金版才有代理帳號
    {
      dataIndex: 'AgentAccount',
      title: i18n.t('agentAccount'),
      excelRender: (val: any, record: any) => {
        if (val) {
          const word = common.agentLevelWord(record.Level);
          return `${word}／${val}`;
        } else return '-';
      }
    },
    // 信用版才有操作帳號
    {
      dataIndex: 'OperatorAccount',
      title: i18n.t('operationAccount'),
      excelRender: (val: any, record: any) => {
        if (val) {
          return `${i18n.t('member')}/${val}`;
        } else return '-';
      }
    },
    {
      title: i18n.t('transactionType'),
      dataIndex: 'KeyId',
      excelRender: (val: any, { Amount }: any) => (
        val === String(AGENT_LOG.agentTransfer) && Amount < 0
          ? i18n.t('agentTransferOut')
          : (
            val === String(AGENT_LOG.agentTransfer) && Amount > 0
              ? i18n.t('agentTransferIng')
              : i18n.t(AGENT_LOG[val])
          )
      )
    },
    // 信用版才有異動帳號
    {
      dataIndex: 'TargetAccount',
      title: i18n.t('changedAccount'),
      render: (val: any, record: any) => {
        if (val) {
          // 0: 會員, 1: 代理
          if (record.TargetRole === 0) {
            return `${i18n.t('member')}/${val}`;
          } else if (record.TargetRole === 1) {
            const word = common.agentLevelWord(record.TargetLevel);
            return `${word}／${val}`;

          } else {
            return val;
          }
        } else return '-';
      }
    },
    {
      title: i18n.t('amountBeforeTransaction'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('transactionAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('amountAfterTransaction'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('updateTime'),
      dataIndex: 'UpdateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('remark'),
      dataIndex: 'Remark',
    },
  ],
  // 財務提款查詢
  MemberWithdraw: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'MemberAccount',
    },
    {
      title: i18n.t('memberLevel'),
      dataIndex: 'MemberLevelName',
    },
    {
      title: i18n.t('memberGroup'),
      dataIndex: 'MemberGroupName',
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UpMemberAccount',
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('withdrawalAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('actualWithdrawalAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number, { Status }: any) => (
        Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(Amount) : '-'
      )
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number, { Status }: any) => (
        Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(AfterAmount) : '-'
      )
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HandlingFee',
      excelRender: (HandlingFee: number, { Status }: any) => (
        Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(HandlingFee) : '-'
      )
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'ExchangeRate',
      render: (val: any, { GatewayCode }: any) =>
        GatewayCode === GATEWAY_CODE_WITHDRAW.線下虛擬 || GatewayCode === GATEWAY_CODE_WITHDRAW.三方虛擬
          ? common.toFormatNumber(val || 0, 2) : '-'
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => i18n.t(WITHDRAW_STATE[Status])
    },
    {
      title: i18n.t('customerServiceReview'),
      dataIndex: 'ServiceAccount',
    },
    {
      title: i18n.t('customerServiceSupervisorReview'),
      dataIndex: 'AuditorAccount',
    },
    {
      title: i18n.t('financialAudit'),
      dataIndex: 'FinanceAccount',
    },
  ],
  // 財務入款查詢
  MemberDeposit: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'MemberAccount',
    },
    {
      title: i18n.t('memberGroup'),
      dataIndex: 'MemberGroupName',
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UpMemberAccount',
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
    },
    {
      title: i18n.t('depositMethod'),
      dataIndex: 'GatewayCode',
      excelRender: (GatewayCode: string) => i18n.t(GatewayCode)
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('depositAmountMoney'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HandlingFee',
      excelRender: (HandlingFee: number, { Status }: any) =>  
        [DEPOSIT_STATE.approvaled, DEPOSIT_STATE.success].includes(Status)
          ? common.toFormatNumber(HandlingFee)
          : '-'
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'ExchangeRate',
      render: (val: any, { GatewayCode }: any) =>
        GatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬 ? common.toFormatNumber(val || 0, 2) : '-'
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('firstDeposit'),
      dataIndex: 'IsFirstDeposit',
      excelRender: (val: any, { Status }: any) => 
        [DEPOSIT_STATE.approvaled, DEPOSIT_STATE.success].includes(Status)
          ? (val ? i18n.t('yes') : i18n.t('none'))
          : ('-')
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => i18n.t(DEPOSIT_STATE[Status])
    },
  ],
  // 總代-代理提款查詢
  AgentWithdraw: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('account'),
      dataIndex: 'AgentAccount',
      excelRender: (SAAccount: string, { AgentLevel }: any) => {
        const word = common.agentLevelWord(AgentLevel);
        return `${word}／${SAAccount}`
      }
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('withdrawalAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('actualWithdrawalAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number, { Status }: any) => (
        Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(Amount) : '-'
      )
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HandlingFee',
      excelRender: (HandlingFee: number, { Status }: any) => (
        Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(HandlingFee) : '-'
      )
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'ExchangeRate',
      render: (val: any, { GatewayCode }: any) =>
        GatewayCode === GATEWAY_CODE_WITHDRAW.線下虛擬 || GatewayCode === GATEWAY_CODE_WITHDRAW.三方虛擬
          ? common.toFormatNumber(val || 0, 2) : '-'
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => i18n.t(WITHDRAW_STATE[Status])
    },
    {
      title: i18n.t('customerServiceReview'),
      dataIndex: 'ServiceAccount',
    },
    {
      title: i18n.t('customerServiceSupervisorReview'),
      dataIndex: 'AuditorAccount',
    },
    {
      title: i18n.t('financialAudit'),
      dataIndex: 'FinanceAccount',
    },
  ],
  // 總代-代理入款查詢
  AgentDeposit: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('account'),
      dataIndex: 'AgentAccount',
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
    },
    {
      title: i18n.t('depositMethod'),
      dataIndex: 'GatewayCode',
      excelRender: (GatewayCode: string) => i18n.t(GatewayCode)
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('depositAmountMoney'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HandlingFee',
      excelRender: (HandlingFee: number, { Status }: any) =>
        [DEPOSIT_STATE.approvaled, DEPOSIT_STATE.success].includes(Status)
          ? common.toFormatNumber(HandlingFee)
          : '-'
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'ExchangeRate',
      render: (val: any, { GatewayCode }: any) =>
        GatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬 ? common.toFormatNumber(val || 0, 2) : '-'
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => i18n.t(DEPOSIT_STATE[Status])
    },
    {
      title: i18n.t('financialAudit'),
      dataIndex: 'FinanceAccount',
    },
  ],
  // 投注報表
  ReportBetting: [
    {
      dataIndex: 'RoundId',
      title: i18n.t('roundNumber'),
    },
    {
      dataIndex: 'MemberAccount',
      title: i18n.t('memberAccount'),
    },
    {
      dataIndex: 'AGAccount',
      title: i18n.t('agent'),
    },
    {
      dataIndex: 'ProviderName',
      title: i18n.t('gameProviderCategory'),
      // FIXME: 這邊沒做SELF轉換
      render: (val: any, { CategoryCode, ProviderCode }: any) =>
        `${ProviderCode}／${CategoryCode ? i18n.t(CategoryCode) : '-'}`
    },
    {
      dataIndex: 'LangName',
      title: i18n.t('gameName'),
      render: (val: any, { GameName }: any) => common.gameNameTransfer(val, GameName) || '-'
    },
    {
      dataIndex: 'BetAmount',
      title: i18n.t('betAmount'),
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'ValidBetAmount',
      title: i18n.t('betAmountValid'),
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'SettleAmount',
      title: i18n.t('payout'),
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'WinLossAmount',
      title: i18n.t('memberWinLoss'),
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'Status',
      title: i18n.t('status'),
      render: (val: any) => i18n.t(REPORT_STATE[val])
    },
    {
      dataIndex: 'BetTime',
      title: i18n.t('betTime'),
      render: (val: any) => common.timeS2L(val)
    },
    {
      dataIndex: 'EndTime',
      title: i18n.t('settlementTime'),
      render: (val: any) => val && val !== '0000-00-00 00:00:00'
        ? common.timeS2L(val)
        : '-'
    },
    {
      dataIndex: 'CancelTime',
      title: i18n.t('cancellationTime'),
      render: (val: any, { Status, EndTime }: any) =>
        (Status === REPORT_STATE.unsettledCancellation || Status === REPORT_STATE.settledCancellation)
          ? common.timeS2L(EndTime)
          : '-'
    }
  ],
  // 統計報表-會員排名
  ReportMemberRank: [
    {
      title: i18n.t('ranking'),
      dataIndex: 'key',
      excelRender: (text: string, record: any, index: number) => index + 1
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'MemberAccount',
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UpMemberAccount',
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
    },
    {
      title: i18n.t('name'),
      dataIndex: 'MemberName',
    },
    {
      title: i18n.t('deposit'),
      dataIndex: 'DepositAmount',
      excelRender: (DepositAmount: number) => common.toFormatNumber(DepositAmount)
    },
    {
      title: i18n.t('withdrawal'),
      dataIndex: 'WithdrawAmount',
      excelRender: (WithdrawAmount: number) => common.toFormatNumber(WithdrawAmount)
    },
    {
      title: i18n.t('betAmountValid'),
      dataIndex: 'ValidBetAmount',
      excelRender: (ValidBetAmount: number) => common.toFormatNumber(ValidBetAmount)
    },
    {
      title: i18n.t('memberWinLoss'),
      dataIndex: 'WinLossAmount',
      excelRender: (WinLossAmount: number) => common.toFormatNumber(WinLossAmount)
    },
  ],
  // 統計報表-遊戲統計分析
  ReportGame: [
    {
      title: i18n.t('gameProvider'),
      dataIndex: 'ProviderCode',
    },
    {
      title: i18n.t('bettingMembers'),
      dataIndex: 'TotalBetMemberCount',
    },
    {
      title: i18n.t('totalBets'),
      dataIndex: 'TotalBetCount',
    },
    {
      title: i18n.t('betAmountValid'),
      dataIndex: 'TotalValidBetAmount',
      excelRender: (TotalValidBetAmount: number) => common.toFormatNumber(TotalValidBetAmount)
    },
    {
      title: i18n.t('totalBetMoney'),
      dataIndex: 'TotalBetAmount',
      excelRender: (TotalBetAmount: number) => common.toFormatNumber(TotalBetAmount)
    },
    {
      title: i18n.t('payoutTotal'),
      dataIndex: 'TotalSettleAmount',
      excelRender: (TotalSettleAmount: number) => common.toFormatNumber(TotalSettleAmount)
    },
    {
      title: i18n.t('companyWinLoss'),
      dataIndex: 'TotalWinLossAmount',
      excelRender: (TotalWinLossAmount: number) => common.toFormatNumber(TotalWinLossAmount)
    },
  ],
  // 營運報表
  ReportBusiness: [
    {
      title: i18n.t('date'),
      dataIndex: 'ReportDate',
    },
    {
      title: i18n.t('newUsers'),
      dataIndex: 'TotalNewMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('activeMembers'),
      dataIndex: 'TotalActivityMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('totalMemberCount'),
      dataIndex: 'TotalDepositRecordCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('totalWithdrawalCount'),
      dataIndex: 'TotalWithdrawRecordCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('firstDepositMembers'),
      dataIndex: 'TotalFirstTimeDepositMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('redepositMembers'),
      dataIndex: 'TotalSecondTimeDepositRecordCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('firstDepositAmount'),
      dataIndex: 'TotalFirstTimeDepositAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('totalDepositAmount'),
      dataIndex: 'TotalDepositAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('totalAmountWithdrawal'),
      dataIndex: 'TotalWithdrawAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('bettingMembers'),
      dataIndex: 'TotalActivityMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('totalBets'),
      dataIndex: 'TotalBetCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('betAmountValid'),
      dataIndex: 'TotalValidBetAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('payout'),
      dataIndex: 'TotalSettleAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('gameProfit'),
      dataIndex: 'TotalWinLossAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('monthlyTotalWinLoss'),
      dataIndex: 'TotalMonthWinLossAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('promotionAmount'),
      dataIndex: 'TotalBonusAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('adjustmentAmount'),
      dataIndex: 'TotalAdjustPlusAmount',
      excelRender: (val: any, { TotalAdjustPlusAmount, TotalAdjustMinusAmount }: any) =>
        `${common.toFormatNumber(TotalAdjustPlusAmount)}／${common.toFormatNumber(TotalAdjustMinusAmount)}`
    },
  ],
  // 代理調帳查詢
  AgentAdjustment: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('agentAccount'),
      dataIndex: 'AgentAccount',
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
    },
    {
      title: i18n.t('transactionType'),
      dataIndex: 'Type',
      excelRender: (Type: any) => i18n.t(ADJUST_TYPE[Type])
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: any) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('amount'),
      dataIndex: 'Amount',
      excelRender: (Amount: any) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: any) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: any) => i18n.t(AGENT_ADJUST_STATE[Status])
    },
    {
      title: i18n.t('audit'),
      dataIndex: 'AuditorAccount',
    },
  ],
  // 交收報表
  ReportSettlement: [
    {
      dataIndex: 'ProviderCode',
      title: i18n.t('gameProvider'),
    },
    {
      dataIndex: 'CategoryCode',
      title: i18n.t('gameCategory'),
      excelRender: (val: any) => i18n.t(val)
    },
    {
      dataIndex: 'Rate',
      title: `${i18n.t('rate')}%`,
      excelRender: (val: any) => common.convertedToPercentage01(val)
    },
    {
      dataIndex: 'BetAmount',
      title: i18n.t('totalBetAmount'),
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'ValidBetAmount',
      title: i18n.t('totalValidBets'),
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'WinLossAmount',
      title: i18n.t('totalGamePnL'),
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'ReceivableAmount',
      title: i18n.t('totalSettlementAmount'),
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'BonusAmount',
      title: i18n.t('totalDiscountAmount'),
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'DonateAmount',
      title: i18n.t('totalDonateAmount'),
      excelRender: (val: any) => common.toFormatNumber(val)
    }
  ],
  // 兌換碼優惠查詢
  CouponQuery: [
    {
      dataIndex: 'RedemptionCode',
      title: i18n.t('couponCodes'),
      __style__: {
        width: 50,
      },
    },
    {
      dataIndex: 'BonusTicketCode',
      title: i18n.t('order'),
      __style__: {
        width: 50,
      },
      excelRender: (val: any) => val || '-'
    },
    {
      dataIndex: 'MemberAccount',
      title: i18n.t('memberAccount'),
      excelRender: (val: any) => val || '-'
    },
    {
      dataIndex: 'MemberLevelName',
      title: i18n.t('memberLevel'),
      excelRender: (val: any) => val || '-'
    },
    {
      dataIndex: 'UpMemberAccount',
      title: i18n.t('recommendedMembers'),
      excelRender: (val: any) => val || '-'
    },
    {
      dataIndex: 'AGAccount',
      title: i18n.t('agent'),
      excelRender: (val: any) => val || '-'
    },
    {
      dataIndex: 'SAAccount',
      title: i18n.t('generalAgent'),
      excelRender: (val: any) => val || '-'
    },
    {
      dataIndex: 'BonusName',
      title: i18n.t('promotionName')
    },
    {
      dataIndex: 'Amount',
      title: i18n.t('redEnvelopeAmount'),
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'ValidBetTimes',
      title: i18n.t('rolloverRate')
    },
    {
      dataIndex: 'RedemptionCodeStatus',
      title: i18n.t('status'),
      excelRender: (val: any) => i18n.t(COUPON_EXPORT_STATUS[val])
    },
    {
      dataIndex: 'RedemptionCodeReceivedTime',
      title: i18n.t('takeTime'),
      excelRender: (val: any) => common.timeS2L(val)
    },
    {
      dataIndex: 'RedemptionCodeCreateDate',
      title: i18n.t('createTime'),
      excelRender: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('promotionalLink'),
      __style__: { width: 80 },
      excelRender: (_: any, record: any) => (
        `https://${record?.otherData.Domain}/red-envelope-code?code=${record?.RedemptionCode}`
      )
    },
  ],
  // 兌換碼優惠匯出
  CouponCodeExport: [
    {
      dataIndex: 'RedemptionCode',
      title: i18n.t('couponCodes'),
      __style__: { width: 50 },
    },
    {
      title: i18n.t('promotionalLink'),
      __style__: { width: 80 },
      excelRender: (_: any, record: any) => (
        `https://${record?.otherData.Domain}/red-envelope-code?code=${record?.RedemptionCode}`
      )
    },
    {
      dataIndex: 'RedemptionCodeStatus',
      title: i18n.t('status'),
      excelRender: (val: number) => i18n.t(COUPON_EXPORT_STATUS[val])
    },
  ],

  // 全民代理查詢-匯出
  peopleAgentSearchExport: [
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'Account',
    },
    {
      title: `${i18n.t('allAgent')}${i18n.t('level')}`,
      dataIndex: 'MLMLevelName',
      excelRender: (val: any) => val || '-'
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UPMemberAccount',
      excelRender: (val: any) => val || '-'
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
      excelRender: (val: any) => val || '-'
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
      excelRender: (val: any) => val || '-'
    },
    {
      title: i18n.t('depositAmounts'),
      dataIndex: 'DepositAmount',
      excelRender: (DepositAmount: number) => common.toFormatNumber(DepositAmount)
    },
    {
      title: i18n.t('personalRebateAmount'),
      dataIndex: 'PersionalCommissionAmount',
      excelRender: (PersionalCommissionAmount: number) => common.toFormatNumber(PersionalCommissionAmount)
    },
    {
      title: i18n.t('rebateAmountDirectDownline'),
      dataIndex: 'DirectCommissionAmount',
      excelRender: (DirectCommissionAmount: number) => common.toFormatNumber(DirectCommissionAmount)
    },
    {
      title: i18n.t('totalNumberCumulativePromoters'),
      dataIndex: 'DirectMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    }
  ],

  // 全民代理返水查詢-匯出
  peopleAgentRebateSearchExport: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'MemberAccount',
    },
    {
      title: `${i18n.t('allAgent')}${i18n.t('level')}`,
      dataIndex: 'MLMLevel',
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UpMemberAccount',
      excelRender: (val: any) => val || '-'
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
      excelRender: (val: any) => val || '-'
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
      excelRender: (val: any) => val || '-'
    },
    {
      title: i18n.t('rebatePeriod'),
      dataIndex: 'CommissionCycle',
      excelRender: (val: any) => i18n.t(SETTLE_CYCLE[val])
    },
    {
      title: i18n.t('betAmountValid'),
      dataIndex: 'ValidBetAmount',
      excelRender: (val: number) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('cashbackType'),
      dataIndex: 'Type',
      excelRender: (val: any) => i18n.t(CASHBACK_TYPE[val])
    },
    {
      title: i18n.t('rebateRatio'),
      dataIndex: 'CommissionRate',
      excelRender: (val: any) => `${$g(val)}%` || '-'
    },
    {
      title: i18n.t('amount'),
      dataIndex: 'Amount',
      excelRender: (val: number) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('rolloverRate'),
      dataIndex: 'ValidBetTimes',
    },
    {
      title: i18n.t('gameCategory'),
      dataIndex: 'GameCategoryCode',
      excelRender: (val: any) => i18n.t(`${val}`)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      excelRender: (val: any) => timeS2L(val)
    },
    {
      title: i18n.t('distributionTime'),
      dataIndex: 'DeliveryDate',
      excelRender: (val: any) => timeS2L(val)
    },
    {
      title: i18n.t('takeTime'),
      dataIndex: 'ReceivedTime',
      excelRender: (val: any) => timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (val: any) => i18n.t(PROMOTION_REBATE_STATE[val])
    }
  ],
}