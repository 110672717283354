import { Form, InputNumber } from 'antd';
import i18n from 'i18n';
import { verify } from 'utils/common';

// 表單元件

// 排序
export const FormInputPosition = ({ className = 'w-full', disabled }: { className?:string, disabled?: boolean }) => {
  return (
    <Form.Item name="Position" label={i18n.t('sort')} className={className}
      rules={[...verify({ point: 0 }), { max: 99, type: 'number', message: `${i18n.t('maximumValueIs99')}` }]}
    >
      <InputNumber className={className} placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
    </Form.Item>
  )
}
