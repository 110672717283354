import { Button, Col, DatePicker, DatePickerProps, Form, Input, Row, Select, Spin, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Content } from "antd/es/layout/layout";
import { LayoutNav } from "components/layout.component";
import { CKEditorComp } from "components/promotion.component";
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT } from "constants/response";
import dayjs, { Dayjs } from "dayjs";
import { WHETHER } from "enum/state";
import i18n from "i18n";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { $get, $post } from "services";
import { enumToOptions, timeL2SDay, timeS2LDay } from "utils/common";
import { COOKIE } from 'constants/cookie';
import { FormInputPosition } from "components/form.component";

// 系統公告編輯

const PageMain = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = useForm();
  const [editorContent, setEditorContent] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(false);
  const { data: AnnouncementInfo, isValidating } = $get({
    url: 'api/contents/one',
    params: { Id: id }
  })
  useEffect(() => {
    if (AnnouncementInfo) {
      form.setFieldsValue({
        title: AnnouncementInfo.Data.Title,
        Position: AnnouncementInfo.Data.Position,
        popup: AnnouncementInfo.Data.LinkTarget,
        marquee: AnnouncementInfo.Data.IsMarquee,
        status: AnnouncementInfo.Data.Status,
        publicationTime: timeS2LDay(AnnouncementInfo.Data.PublishDate),
        endTime: timeS2LDay(AnnouncementInfo.Data.EndTime),
        internalContent: AnnouncementInfo.Data.Content
      })
      setEditorContent(AnnouncementInfo.Data.Content);
    }
  }, [AnnouncementInfo])

  const onDateOk: DatePickerProps['onOk'] = (date) => {
    if (date.get('D') === dayjs().get('D') &&
      (date.get('hours') < dayjs().get('hours') || date.get('minutes') < dayjs().get('minutes'))) {
      const resetDate = dayjs().set('years', date.year()).set('D', dayjs().get('D')).set('hours', dayjs().get('hours')).set('minutes', dayjs().get('minutes'));
      form.setFieldValue('publicationTime', dayjs(resetDate));
    }
  }

  const onFinish = (formData: any) => {
    const Lang = Cookies.get(COOKIE.LANG)
    $post({
      url: 'api/contents/announcement/update',
      send: {
        Id: id,
        Lang,
        Title: formData.title,
        Content: editorContent,
        Position: formData.Position,
        PublishDate: timeL2SDay(formData.publicationTime, 'YYYY-MM-DD HH:mm:00Z'),
        EndDate: timeL2SDay(formData.endTime, 'YYYY-MM-DD HH:mm:00Z'),
        LinkTarget: formData.popup,
        IsMarquee: formData.marquee,
        Status: formData.status,
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onCancel();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    })
  }

  const onCancel = () => {
    form.resetFields();
    navigate('/advanced/announcement');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Spin spinning={isValidating}>
          <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
            Position: 0,
            status: 1,
          }}>
            <Row gutter={[12, 8]}>
              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Col>
                    <Form.Item name="title" label={i18n.t('title')} rules={[
                      { required: true },
                      { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
                    ]}>
                      <Input className="w-18" placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Row align="middle" gutter={[12, 12]}>
                      <Col>
                        <Button onClick={onCancel}>{i18n.t('cancel')}</Button>
                      </Col>
                      <Col>
                        <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <FormInputPosition className="w-12" />
              </Col>
              <Col span={24}>
                <Form.Item name="popup" label={i18n.t('popup')} rules={[{ required: true }]} className="w-12">
                  <Select
                    placeholder={i18n.t('pleaseSelect')}
                    options={enumToOptions(WHETHER)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="marquee" label={i18n.t('marquee')} rules={[{ required: true }]} className="w-12">
                  <Select
                    placeholder={i18n.t('pleaseSelect')}
                    options={enumToOptions(WHETHER)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]} className="w-12">
                  <Select
                    placeholder={i18n.t('pleaseSelect')}
                    options={[
                      { value: 1, label: i18n.t('enabled') },
                      { value: 0, label: i18n.t('disabled') },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="w-12" name="publicationTime" label={i18n.t('publicationTime')}
                  rules={[{ required: true }]}>
                  <DatePicker
                    onChange={() => {
                      form.getFieldValue('publicationTime')
                        ? setIsDisabled(false) : setIsDisabled(true)
                      form.setFieldValue('endTime', '');
                    }}
                    disabledDate={(current: Dayjs) => (current < dayjs().startOf('D'))}
                    disabledTime={(date) => {
                      const today = date?.date() !== dayjs().date();
                      const hours = [...Array(24).keys()];
                      const minutes = [...Array(60).keys()];
                      if (today) {
                        return {
                          disabledHours: () => [],
                          disabledMinutes: () => [],
                        }
                      } else {
                        return {
                          disabledHours: () => hours.filter(h => h < dayjs().hour()),
                          disabledMinutes: () => minutes.filter(m => m < dayjs().minute()),
                        }
                      }
                    }}
                    showTime={{ format: 'HH:mm' }}
                    className="w-full"
                    format={"YYYY-MM-DD HH:mm:00"}
                    onOk={onDateOk}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="w-12" name="endTime" label={i18n.t('endTime')}
                  rules={[{ required: true }]} >
                  <DatePicker
                    disabled={isDisabled}
                    disabledDate={(current: Dayjs) => (current < dayjs(form.getFieldValue('publicationTime')))}
                    disabledTime={(date) => {
                      const today = date?.date() !== dayjs().date();
                      const hours = [...Array(24).keys()];
                      const minutes = [...Array(60).keys()];
                      if (today) {
                        return {
                          disabledHours: () => [],
                          disabledMinutes: () => [],
                        }
                      } else {
                        return {
                          disabledHours: () => hours.filter(h => h < dayjs().hour()),
                          disabledMinutes: () => minutes.filter(m => m < dayjs().minute()),
                        }
                      }
                    }}
                    showTime={{ format: 'HH:mm' }}
                    className="w-full"
                    format={"YYYY-MM-DD HH:mm:00"}
                    onOk={onDateOk}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="internalContent" label={i18n.t('internalContent')} rules={[{ required: true }]}>
                  <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content>
    </div >
  )
}

export default PageMain;